import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  dateDiffYears,
  dateTimeToS,
  dateToApi,
  dateToMMMMYYYY,
  dateToYYYYMM,
  dateUtcToS,
  dateUtcToyyyyMMdd,
} from '../../util/dateUtil';
import { getRowFromListById } from '../../util/miscUtil';
import { appInsights, logException } from '../../services/appInsights';
import { logAudit } from '../../util/auditUtil';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import TableStyle from '../../styles/TableStyle';
import TitleBar from '../Shared/TitleBar';
import { inject, observer } from 'mobx-react';
import AddEmailDialog from './AddEmailDialog';
import { btMediumGrayColor, btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import SeniorListCards from './SeniorListCards';
import { callApi } from '../../util/apiWrapper';
import Paper from '@material-ui/core/Paper';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FilterIcon from '@material-ui/icons/Filter';
import FilterDialog from './SeniorListDialogs/FilterDialog';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles(() => ({
  titlebar: {
    backgroundColor: '#fff',
    padding: '30px 10px 5px 10px',
    margin: '-15px 0 15px 0',
    borderBottom: '1px inset #f0f0f0',
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  idLink: {
    color: btNavyBlueColor,
    fontSize: '0.85rem',
    padding: 0,
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
    textDecoration: 'underline',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20,
    marginTop: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  filter: {
    margin: '5px 0 15px 20px',
    borderRadius: '6px 0 0 6px',
  },
  toolMargins: {
    margin: '5px 0 0 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
    lineHeight: '30px',
    verticalAlign: 'middle',
  },
  switchAlignment: {
    lineHeight: '28px',
    verticalAlign: 'middle',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
  enhancedFilterButton: {
    marginTop: 5,
    minHeight: 37,
    borderRadius: '0 6px 6px 0',
  },
}));

const SeniorListTable = inject(
  'SeniorStore',
  'AuthStore',
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const history = useHistory();

    const classes = useStyles();
    const tableClasses = tableStyles();

    const [sortBy, setSortBy] = React.useState('');
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [filter, setFilter] = React.useState('');
    const [allseniors, setAllSeniors] = React.useState([]);
    const [seniors, setSeniors] = React.useState([]);
    const [showExpandedFilter, setShowExpandedFilter] = useState(false);
    const [aftercareSpecialist, setAftercareSpecialist] = useState(0);
    const [aftercareSpecialistName, setAftercareSpecialistName] = useState('');
    const [pageTitle, setPageTitle] = useState('High School Seniors');

    const EPOCH_DATE = new Date(0);
    const CURRENT_DATE = new Date();

    const createUniqueList = (list) => {
      let unique = [];
      list.forEach((s) => {
        unique.push({
          'id': s.id,
          'int_id': s.int_id,
          'site': s.site,
          'first_name': s.first_name,
          'last_name': s.last_name,
          'community': s.community,
          'program': s.program,
          'user': s.user,
          'admission_date': s.admission_date,
          'alumni_updated_first_name': s.alumni_updated_first_name,
          'alumni_updated_last_name': s.alumni_updated_last_name,
          'dob': s.dob,
          'aftercare_specialist': s.aftercare_specialist,
          'departure_reason': s.departure_reason,
          'favorable_discharge': s.favorable_discharge,
          'graduation_date': s.graduation_date,
          'sex': s.sex,
        });
      });
      return unique.filter((obj, index) => unique.findIndex((item) => item.int_id === obj.int_id) === index);
    };

    useEffect(() => {
      async function fetchSeniors() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const seniorData = await callApi(auth, 'student?roleId=1', 'GET');
          const allseniors = seniorData && seniorData.result;
          const siteseniors = allseniors.filter((s) => store.staffsites.includes(s.site));
          store.setSenior(null);
          store.setSeniorData(siteseniors);
          store.setAllSeniors(siteseniors);
          setAllSeniors(siteseniors);
          setSeniors(siteseniors);
          store.setSearching(false);
          store.setLoading(false);
          setSortBy('last_name');
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error fetching senior list:', error);
        }
      }

      if (auth) {
        fetchSeniors();
      }
    }, [auth, store]);

    useEffect(() => {
      if (auth && store.allseniors) {
        setAllSeniors(store.allseniors);
        setSeniors(store.allseniors);
      }
    }, [auth, store]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          if (key === 'user') {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key].email;
            }
            if (b[key]) {
              bVal = b[key].email;
            }
            const varA = standarizeEmail(aVal);
            const varB = standarizeEmail(bVal);
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'aftercare_specialist') {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
              return 0;
            }
            const varA = getSpecialistInfo(a[key]);
            const varB = getSpecialistInfo(b[key]);
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'graduation_date') {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] && a[key].length > 0 ? dateToYYYYMM(a[key]) : '000000';
            }
            if (b[key]) {
              bVal = b[key] && b[key].length > 0 ? dateToYYYYMM(b[key]) : '000000';
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else if (key === 'dob') {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] ? dateUtcToyyyyMMdd(a[key]) : '00000000';
            }
            if (b[key]) {
              bVal = b[key] ? dateUtcToyyyyMMdd(b[key]) : '00000000';
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          } else {
            let aVal = '';
            let bVal = '';
            if (a[key]) {
              aVal = a[key] ? a[key] : '';
            }
            if (b[key]) {
              bVal = b[key] ? b[key] : '';
            }
            const varA = aVal;
            const varB = bVal;
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
          }
        };
      };

      let filteredData = allseniors;

      // Enhanced Filter
      setPageTitle('High School Seniors');
      if (store.advancedFilter === 'all') {
        filteredData = allseniors;
        setPageTitle('High School Seniors');
      }

      if (store.advancedFilterStatus === 'active') {
        filteredData = filteredData.filter((s) => s.user?.is_active);
      }
      if (store.advancedFilterStatus === 'inactive') {
        filteredData = filteredData.filter((s) => s.user?.is_active === false);
      }

      if (store.advancedFilterLoggedIn === 'loggedin') {
        filteredData = filteredData.filter((s) => s.user?.last_login);
      }
      if (store.advancedFilterLoggedIn === 'neverloggedin') {
        filteredData = filteredData.filter((s) => !s.user?.last_login);
      }

      if (store.advancedFilterSite === 'homecampus') {
        filteredData = filteredData.filter((s) => s.site === 'Home Campus');
      }
      if (store.advancedFilterSite === 'sites') {
        filteredData = filteredData.filter((s) => s.site !== 'Home Campus');
      }

      if (store.advancedFilterProgram === 'FFS') {
        filteredData = filteredData.filter((s) => s.program && s.program === 'FFS');
      }
      if (store.advancedFilterProgram === 'FHP') {
        filteredData = filteredData.filter((s) => s.program && s.program === 'FHP');
      }

      if (store.advancedFilterChecklist === 'checklistinprogress') {
        filteredData = filteredData.filter(
          (s) => s.checklist_data && (JSON.parse(s.checklist_data).status === 'created' || JSON.parse(s.checklist_data).status === 'returned'),
        );
      }
      if (store.advancedFilterChecklist === 'checklistsubmitted') {
        filteredData = filteredData.filter((s) => s.checklist_data && JSON.parse(s.checklist_data).status === 'submitted');
      }
      if (store.advancedFilterChecklist === 'checklistapproved') {
        filteredData = filteredData.filter((s) => s.checklist_data && JSON.parse(s.checklist_data).status === 'approved');
      }

      if (store.advancedFilterPlanningForm1 === 'planning1inprogress') {
        filteredData = filteredData.filter(
          (s) =>
            s.planning_form_1_data &&
            (JSON.parse(s.planning_form_1_data).status === 'created' || JSON.parse(s.planning_form_1_data).status === 'returned'),
        );
      }
      if (store.advancedFilterPlanningForm1 === 'planning1submitted') {
        filteredData = filteredData.filter((s) => s.planning_form_1_data && JSON.parse(s.planning_form_1_data).status === 'submitted');
      }
      if (store.advancedFilterPlanningForm1 === 'planning1approved') {
        filteredData = filteredData.filter(
          (s) =>
            s.planning_form_1_data &&
            (JSON.parse(s.planning_form_1_data).status === 'approved' || JSON.parse(s.planning_form_1_data).status === 'approvedalternate'),
        );
      }
      if (store.advancedFilterPlanningForm1 === 'planning1followup') {
        filteredData = filteredData.filter((s) => s.planning_form_1_data && JSON.parse(s.planning_form_1_data).followup);
      }

      if (store.advancedFilterPlanningForm2 === 'planning2inprogress') {
        filteredData = filteredData.filter(
          (s) =>
            s.planning_form_2_data &&
            (JSON.parse(s.planning_form_2_data).status === 'created' || JSON.parse(s.planning_form_2_data).status === 'reopened'),
        );
      }
      if (store.advancedFilterPlanningForm2 === 'planning2submitted') {
        filteredData = filteredData.filter((s) => s.planning_form_2_data && JSON.parse(s.planning_form_2_data).status === 'submitted');
      }
      if (store.advancedFilterPlanningForm2 === 'planning2approved') {
        filteredData = filteredData.filter(
          (s) =>
            s.planning_form_2_data &&
            (JSON.parse(s.planning_form_2_data).status === 'approved' || JSON.parse(s.planning_form_2_data).status === 'approvedalternate'),
        );
      }
      if (store.advancedFilterPlanningForm2 === 'planning2followup') {
        filteredData = filteredData.filter((s) => s.planning_form_2_data && JSON.parse(s.planning_form_2_data).followup);
      }

      if (store.advancedFilterScholarship === 'notstartedscholarship') {
        filteredData = filteredData.filter((s) => !s.scholarship_app_status);
      }
      if (store.advancedFilterScholarship === 'scholarshipinprogress') {
        filteredData = filteredData.filter((s) => s.scholarship_app_status && s.scholarship_app_status === 'created');
      }
      if (store.advancedFilterScholarship === 'submittedscholarship') {
        filteredData = filteredData.filter((s) => s.scholarship_app_status && s.scholarship_app_status !== 'created');
      }

      if (store.advancedFilterAftercare === 'notstartedaftercareapp') {
        filteredData = filteredData.filter((s) => !s.aftercare_app_status);
      }
      if (store.advancedFilterAftercare === 'aftercareappinprogress') {
        filteredData = filteredData.filter((s) => s.aftercare_app_status && s.aftercare_app_status === 'created');
      }
      if (store.advancedFilterAftercare === 'submittedaftercareapp') {
        filteredData = filteredData.filter((s) => s.aftercare_app_status && s.aftercare_app_status !== 'created');
      }

      if (aftercareSpecialist && aftercareSpecialist > 0) {
        filteredData = filteredData.filter((s) => s.aftercare_specialist === aftercareSpecialist);
      }

      if (
        store.advancedFilterStatus === '' &&
        store.advancedFilterLoggedIn === '' &&
        store.advancedFilterSite === '' &&
        store.advancedFilterProgram === '' &&
        store.advancedFilterChecklist === '' &&
        store.advancedFilterPlanningForm1 === '' &&
        store.advancedFilterPlanningForm2 === '' &&
        store.advancedFilterScholarship === '' &&
        store.advancedFilterAftercare === ''
      ) {
        store.setAdvancedFilter('all');
      }

      // Filter Field
      if (filter && filter.length > 0) {
        filteredData = filteredData.filter(
          (s) =>
            (s.int_id && s.int_id.toString().includes(filter)) ||
            (s.first_name && s.first_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.last_name && s.last_name.toLowerCase().includes(filter.toLowerCase())) ||
            (s.dob && dateUtcToS(s.dob).toString().includes(filter)) ||
            (s.graduation_date &&
              s.graduation_date > EPOCH_DATE &&
              dateToMMMMYYYY(s.graduation_date).toString().toLowerCase().includes(filter.toLowerCase())) ||
            (s.graduation_date &&
              s.graduation_date > EPOCH_DATE &&
              dateDiffYears(dateToApi(s.dob), dateToApi(s.graduation_date)).toString().includes(filter)) ||
            (s.site && s.site.toLowerCase().includes(filter.toLowerCase())) ||
            (s.user.email && standarizeEmail(s.user.email).toString().toLowerCase().includes(filter.toLowerCase())) ||
            (s.aftercare_specialist && getSpecialistInfo(s.aftercare_specialist).toString().toLowerCase().includes(filter.toLowerCase())) ||
            (s.program && s.program.toLowerCase().includes(filter.toLowerCase())),
        );
      }

      store.setPage(0);
      if (store.showAllRecords) {
        store.setTotalSeniors(filteredData.length);
      } else {
        filteredData = filteredData.filter((x) => x.departure_date === undefined);
        store.setTotalSeniors(filteredData.length);
      }
      if (store.showNeedEmail) {
        filteredData = filteredData.filter((x) => x.user.id === 0);
        store.setTotalSeniors(filteredData.length);
      }

      // Checkbox Filters
      store.setPage(0);
      if (store.showAllRecords) {
        store.setTotalSeniors(filteredData.length);
      } else {
        filteredData = filteredData.filter((x) => x.departure_date === undefined);
        store.setTotalSeniors(filteredData.length);
      }
      if (store.showNeedEmail) {
        filteredData = filteredData.filter((x) => x.user.id === 0);
        store.setTotalSeniors(filteredData.length);
      }

      // SORTING
      filteredData = filteredData.sort(compareValues(sortBy, sortDirection));

      setSeniors(filteredData);
      store.setAllSeniors(filteredData);
    }, [
      store,
      sortBy,
      filter,
      aftercareSpecialist,
      sortDirection,
      allseniors,
      store.advancedFilter,
      store.advancedFilterStatus,
      store.advancedFilterLoggedIn,
      store.advancedFilterSite,
      store.advancedFilterProgram,
      store.advancedFilterChecklist,
      store.advancedFilterPlanningForm1,
      store.advancedFilterPlanningForm2,
      store.advancedFilterScholarship,
      store.advancedFilterAftercare,
      store.showNeedEmail,
      store.showAllRecords,
    ]);

    const setSort = (val) => {
      if (val === sortBy) {
        if (sortDirection === 'asc') {
          setSortDirection('desc');
        } else {
          setSortDirection('asc');
        }
      } else {
        setSortBy(val);
        setSortDirection('asc');
      }
    };

    const standarizeEmail = (e) => {
      if (e) {
        const emailParts = e.split('@');
        const domainParts = emailParts[1].split('.');
        return emailParts[0].toLowerCase() + '@' + domainParts[0].toLowerCase() + '.' + domainParts[1].toLowerCase();
      } else {
        return '';
      }
    };

    const filterData = (val) => {
      setFilter(val);
    };

    const clearExpandedFilter = () => {
      store.setAdvancedFilter('');
      store.setAdvancedFilterStatus('');
      store.setAdvancedFilterLoggedIn('');
      store.setAdvancedFilterSite('');
      store.setAdvancedFilterProgram('');
      store.setAdvancedFilterChecklist('');
      store.setAdvancedFilterPlanningForm1('');
      store.setAdvancedFilterPlanningForm2('');
      store.setAdvancedFilterScholarship('');
      store.setAdvancedFilterAftercare('');
      store.setAdvancedFilter('all');
    };

    const getSpecialistInfo = (id) => {
      const spec = getRowFromListById(store.aftercareSpecialistList, id);
      if (spec) {
        return spec.user.first_name + ' ' + spec.user.last_name;
      }
      console.warn('Some seniors are assigned to invalid Successful Future specialists.');
      return '';
    };

    const handleClickId = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setBasicInfoId(id);
      store.setAlumni(false);
      history.push('/seniorplanner');
    };

    const handleClickAddEmail = (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setAddEmailId(id);
      store.setShowAddEmail(true);
    };

    const handleEmailClose = () => {
      store.setShowAddEmail(false);
    };

    const refreshPage = () => {
      window.location.reload(true);
    };

    const handleHelpClose = () => {
      setShowExpandedFilter(false);
    };

    const handleClickResend = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'studentonboardemail', 'POST', { student_id: id });
        let allseniors = store.allseniors.slice();
        let i = allseniors.findIndex((v) => v.id === id);
        let tempSenior = JSON.parse(JSON.stringify(allseniors[i]));
        tempSenior.user.updated_at = CURRENT_DATE;
        allseniors[i] = tempSenior;
        store.setSenior(tempSenior);
        setSeniors(allseniors);
        store.setSeniorData(allseniors);
        store.setAllSeniors(allseniors);
        store.setSearching(false);
        store.setLoading(false);
        setSort('last_name');
        setSortDirection('asc');

        appInsights.trackTrace({ message: `Invitation resent to ${id}.` });
        const auditObj = {
          audit_ts: CURRENT_DATE,
          user_id: auth.user.result.id,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'invitation',
          action: 'resent',
          value: `Invitation resent to ${id}.`,
        };
        logAudit(auth, auditObj);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error resending invitation:', error);
      }
    };

    const handleCardSortChange = (event) => {
      setSort(event.target.value);
    };

    const emptyRows = store.perPage - Math.min(store.perPage, seniors && seniors.length - store.page * store.perPage);

    return (
      <Grid container className={classes.whiteBg}>
        {auth.roles && !auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
          <React.Fragment>
            <PleaseWait isLoading={store.loading} />
            <TitleBar
              title={pageTitle}
              currentPage="High School Senior List"
              paths={[{ path: '/', name: 'Home' }]}
              additionalInformation={aftercareSpecialistName ? `Specialist: ${aftercareSpecialistName}` : ''}
            />
            <Grid container className={classes.whiteBg}>
              <Grid item xs={12} className={classes.whiteBg} align="left">
                <Grid container direction="row">
                  <Grid item xs={12} sm={12} md={4} lg={3} className={classes.whiteBg} align="left">
                    <TextField
                      id="filter-field"
                      type="search"
                      className={classes.filter}
                      size="small"
                      hiddenLabel
                      placeholder="Filter"
                      variant="outlined"
                      onChange={(event) => filterData(event.target.value)}
                      clearable="true"
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                color="primary"
                                variant="contained"
                                title="Enhanced Filter"
                                aria-describedby="Expand Filter Options"
                                onClick={() => setShowExpandedFilter(true)}
                              >
                                <FilterIcon />
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                color="primary"
                                variant="contained"
                                title="Clear Enhanced Filter"
                                aria-describedby="Celar Filter Options"
                                onClick={() => clearExpandedFilter()}
                              >
                                <CancelPresentationIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={4} className={classes.whiteBg} align="left"></Grid>
                  <Grid item xs={12} sm={5} md={4} lg={5} align="right" className={classes.whiteBg}>
                    <Hidden smDown implementation="css" className={classes.toolMargins}>
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Show Only Students Without Email"
                        onChange={() => store.setShowNeedEmail(!store.showNeedEmail)}
                        checked={store.showNeedEmail}
                      />
                    </Hidden>
                  </Grid>
                </Grid>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow style={{ width: '100%' }}>
                          <Tooltip title="Click to sort by Last Name." placement="top">
                            <TableCell onClick={() => setSort('last_name')} className={classes.sortHeader}
                                       style={{ width: '240px' }}>
                              NAME (NDB ID){' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'last_name' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'last_name' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Birth Date." placement="top">
                            <TableCell onClick={() => setSort('dob')} className={classes.sortHeader}
                                       style={{ width: '90px' }}>
                              BIRTH DATE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'dob' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'dob' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Graduation Date." placement="top">
                            <TableCell onClick={() => setSort('graduation_date')} className={classes.sortHeader}
                                       style={{ width: '120px' }}>
                              GRADUATION (AGE){' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'graduation_date' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'graduation_date' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          {store.showAllRecords && (
                            <TableCell className={classes.nosortHeader} style={{ width: '90px' }}>
                              DEPARTURE
                            </TableCell>
                          )}
                          <Tooltip title="Click to sort by Site." placement="top">
                            <TableCell onClick={() => setSort('site')} className={classes.sortHeader}
                                       style={{ width: '125px' }}>
                              SITE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'site' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'site' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Email." placement="top">
                            <TableCell onClick={() => setSort('user')} className={classes.sortHeader}
                                       style={{ width: '180px' }}>
                              EMAIL ADDRESS{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'user' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'user' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Specialist." placement="top">
                            <TableCell onClick={() => setSort('aftercare_specialist')} className={classes.sortHeader}
                                       style={{ width: '120px' }}>
                              SPECIALIST{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'aftercare_specialist' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'aftercare_specialist' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by Program." placement="top">
                            <TableCell onClick={() => setSort('program')} className={classes.sortHeader}
                                       style={{ width: '100px' }}>
                              PROGRAM{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: sortBy === 'program' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: sortBy === 'program' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <TableCell className={classes.nosortHeader} style={{ width: '110px' }}>
                            RESEND INVITATION
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {store.searching ? (
                          <TableRow style={{ width: '100%' }}>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          seniors &&
                          createUniqueList(seniors)
                            .slice(store.page * store.perPage, store.page * store.perPage + store.perPage)
                            .map((senior, idx) => (
                              <TableRow
                                style={{ width: '100%' }}
                                key={senior.id + '_' + idx}
                                className={classes.pointer}
                                onClick={(ev) => handleClickId(ev, senior.id)}
                              >
                                <TableCell style={{ width: '240px' }}>
                                  {senior.last_name}, {senior.first_name} ({senior.int_id})
                                </TableCell>
                                <TableCell style={{ width: '90px' }}>{dateUtcToS(senior.dob)}</TableCell>
                                <TableCell style={{ width: '120px' }}>
                                  {senior.graduation_date > EPOCH_DATE && dateToMMMMYYYY(senior.graduation_date)}
                                  {senior.graduation_date > EPOCH_DATE &&
                                    ' (' + dateDiffYears(dateToApi(senior.dob), dateToApi(senior.graduation_date)) + ')'}
                                </TableCell>
                                {store.showAllRecords && (
                                  <TableCell style={{ width: '90px' }}>
                                    {senior.departure_date > EPOCH_DATE && dateUtcToS(senior.departure_date)}
                                  </TableCell>
                                )}
                                <TableCell
                                  style={{ width: '125px' }}>{!senior.departure_date && senior.site && senior.site}</TableCell>
                                <TableCell style={{ width: '180px' }}>
                                  {senior.departure_date && senior.user.email &&
                                    <label className={classes.idNoLink}>{senior.user.email}</label>}
                                  {!senior.departure_date && senior.user.email ? (
                                    <React.Fragment>{senior.user.email}</React.Fragment>
                                  ) : (
                                    !senior.departure_date && (
                                      <React.Fragment>
                                        <Button
                                          className={classes.gridLink}
                                          title="Add email address for student."
                                          aria-describedby="Add email address for senior"
                                          onClick={(ev) => handleClickAddEmail(ev, senior.id)}
                                        >
                                          Add Email Address
                                        </Button>
                                      </React.Fragment>
                                    )
                                  )}
                                </TableCell>
                                <TableCell style={{ width: '120px' }}>
                                  {senior.aftercare_specialist > 0 ? getSpecialistInfo(senior.aftercare_specialist) : ''}
                                </TableCell>
                                <TableCell style={{ width: '100px' }}>{senior.program && senior.program}</TableCell>
                                <TableCell style={{ width: '110px' }} className={classes.centerAlignContent}>
                                  {!senior.departure_date && senior.user.created_at && (
                                    <React.Fragment>
                                      <Tooltip
                                        title={`Invited on ${dateTimeToS(senior.user.updated_at)}. Resend invitation.`}
                                        placement="left">
                                        <IconButton
                                          className={classes.gridLink}
                                          aria-describedby="Resend invitation"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            handleClickResend(senior.id);
                                          }}
                                        >
                                          <MailOutlineIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    </React.Fragment>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                        {!store.searching && seniors && seniors.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100, 150, 200, 250]}
                      count={store.seniorPages}
                      rowsPerPage={store.perPage}
                      page={store.page}
                      onPageChange={(ev, val) => store.handlePageChange(ev, val)}
                      onRowsPerPageChange={(ev) => store.handleChangeRowsPerPage(ev)}
                    />
                  </TableContainer>
                </Hidden>
                <Hidden lgUp implementation="css">
                  <Grid container>
                    <Grid item xs={12} sm={7} md={5} className={classes.radioRow}>
                      <RadioGroup
                        aria-label="card-sorting"
                        name="date-group"
                        className={classes.centerRadioSorting}
                        row
                        onChange={handleCardSortChange}
                      >
                        <FormControlLabel
                          checked={sortBy === 'int_id' ? true : false}
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Id</span>}
                          onClick={() => setSort('int_id')}
                          className={classes.radioLabel}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: sortBy === 'int_id' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: sortBy === 'int_id' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          checked={sortBy === 'last_name' ? true : false}
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Last Name</span>}
                          onClick={() => setSort('last_name')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: sortBy === 'last_name' ? (sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: sortBy === 'last_name' ? (sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  {seniors && seniors.length > 0 &&
                    <SeniorListCards SeniorStore={store} seniors={seniors} resendEmail={handleClickResend} />}
                </Hidden>
              </Grid>
            </Grid>
            <AddEmailDialog SeniorStore={store} AuthStore={auth} handleClose={handleEmailClose} refresh={refreshPage} />
          </React.Fragment>
        )}
        <FilterDialog
          filterOption={store.advancedFilter}
          setFilterOption={store.setAdvancedFilter}
          filterAll={store.advancedFilter}
          setFilterAll={store.setAdvancedFilter}
          filterStatus={store.advancedFilterStatus}
          setFilterStatus={store.setAdvancedFilterStatus}
          filterLoggedIn={store.advancedFilterLoggedIn}
          setFilterLoggedIn={store.setAdvancedFilterLoggedIn}
          filterProgram={store.advancedFilterProgram}
          setFilterProgram={store.setAdvancedFilterProgram}
          filterChecklist={store.advancedFilterChecklist}
          setFilterChecklist={store.setAdvancedFilterChecklist}
          filterPlanningForm1={store.advancedFilterPlanningForm1}
          setFilterPlanningForm1={store.setAdvancedFilterPlanningForm1}
          filterPlanningForm2={store.advancedFilterPlanningForm2}
          setFilterPlanningForm2={store.setAdvancedFilterPlanningForm2}
          filterScholarship={store.advancedFilterScholarship}
          setFilterScholarship={store.setAdvancedFilterScholarship}
          filterAftercare={store.advancedFilterAftercare}
          setFilterAftercare={store.setAdvancedFilterAftercare}
          filterSite={store.advancedFilterSite}
          setFilterSite={store.setAdvancedFilterSite}
          aftercareList={store.aftercareSpecialistList}
          aftercareSpecialist={aftercareSpecialist}
          setAftercareSpecialist={setAftercareSpecialist}
          setAftercareSpecialistName={setAftercareSpecialistName}
          showDialog={showExpandedFilter}
          handleClose={handleHelpClose}
        />
      </Grid>
    );
  }),
);

export default SeniorListTable;
