import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Grid, IconButton, Snackbar } from '@material-ui/core';
import { logException } from '../../services/appInsights';
import { callApi } from '../../util/apiWrapper';
import { makeStyles } from '@material-ui/core/styles';
import { getRowFromListById } from '../../util/miscUtil';
import HelpDialog from '../Help/HelpDialog';
import {
  btMediumGrayColor,
  secondaryColor,
  webPrimaryColor,
  webSecondaryColor,
  whiteColor,
} from '../../assets/jss/nextjs-material-kit-pro.js';
import BoysTownLogo from '../../assets/img/bt.jpg';
import Home from './SeniorPlannerComponents/Home';
import HelpApps from './SeniorPlannerComponents/HelpApps';
import AftercareApps from './SeniorPlannerComponents/AftercareApps';
import ScholarshipApps from './SeniorPlannerComponents/ScholarshipApps';
import Recommendations from './SeniorPlannerComponents/Recommendations';
import BasicInfo from './SeniorPlannerComponents/BasicInfo';
import HelpApp from './SeniorPlannerComponents/HelpApp';
import Planning1 from './SeniorPlannerComponents/Planning1';
import Planning2 from './SeniorPlannerComponents/Planning2';
import Checklist from './SeniorPlannerComponents/Checklist';
import Aftercare from './SeniorPlannerComponents/Aftercare';
import Scholarship from './SeniorPlannerComponents/Scholarship';
import Recommendation from './SeniorPlannerComponents/Recommendation';
import Tips from './SeniorPlannerComponents/Tips';
import Life from './SeniorPlannerComponents/Life';
import CloseIcon from '@material-ui/icons/Close';
import useAuthRedirect from '../../hooks/authRedirectHook';

const useStyles = makeStyles((theme) => ({
  welcome: {
    fontSize: '1.188rem',
    color: webSecondaryColor,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      margin: '25px 10px 10px 10px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0 10px 0',
    },
  },
  adminWelcome: {
    fontSize: '1.188rem',
    color: webSecondaryColor,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      margin: '5px 10px 10px 20px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0 10px 0',
    },
  },
  alumniWelcome: {
    fontSize: '1.188rem',
    lineHeight: '1.625rem',
    color: webSecondaryColor,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      margin: '25px 10px 10px 10px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 0 25px 0',
    },
  },
  alumniIntro: {
    margin: '2% 5% 10px 5%',
    fontSize: '1.063rem',
    lineHeight: '1.513rem',
    color: webPrimaryColor,
    textAlign: 'left',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 30px',
  },
  subMenu: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 30px',
  },
  menuTitle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 24,
    textAlign: 'left',
    margin: '25px 0 10px 0',
  },
  menuDesc: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.00rem',
    lineHeight: '1.313rem',
    textAlign: 'center',
    padding: '5px',
    color: webPrimaryColor,
  },
  appSubmittedDate: {
    fontSize: '1.50rem',
    color: webPrimaryColor,
    margin: '15px 0 50px 0',
    padding: 10,
    textAlign: 'center',
  },
  recommendationDates: {
    fontSize: '1.50rem',
    color: webPrimaryColor,
    margin: '15px 0',
    padding: 10,
  },
  helpSubmittedDate: {
    fontSize: '1.50rem',
    color: webPrimaryColor,
    margin: '10px 0 8px 0',
    padding: 10,
    textAlign: 'center',
  },
  appStatus: {
    fontSize: '1.188rem',
    color: webPrimaryColor,
    position: 'relative',
    bottom: '5px',
  },
  statusIcon: {
    fontSize: 20,
  },
  warningStatusIcon: {
    fontSize: 20,
    color: secondaryColor,
  },
  appStatusDate: {
    fontSize: '1.188rem',
    position: 'relative',
    bottom: '5px',
    paddingLeft: 25,
  },
  statusBottom: {
    marginLeft: 10,
    position: 'absolute',
    bottom: '20px',
    left: '15%',
  },
  printMenuDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: '#000',
  },
  headerDesc: {
    width: '90%',
    fontSize: '1.063rem',
    lineHeight: '1.313rem',
    padding: '20px 0 5px 0',
    textAlign: 'left',
    color: webPrimaryColor,
  },
  printHeaderDesc: {
    width: '90%',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 0',
    color: webPrimaryColor,
  },
  icon: {
    fontSize: '100px',
  },
  cardHeader: {
    color: webPrimaryColor,
    fontSize: '1.175rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    textAlign: 'center',
    minHeight: '3.15rem',
  },
  iconText: {
    color: webPrimaryColor,
    fontSize: '1.188rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
  },
  nav: {
    cursor: 'pointer',
  },
  homeIconSpace: {
    marginTop: 50,
  },
  card: {
    backgroundColor: whiteColor,
    [theme.breakpoints.down('sm')]: {
      width: '235px',
      height: '235px',
    },
    [theme.breakpoints.up('md')]: {
      width: '235px',
      height: '235px',
    },
    margin: '0, 5px',
  },
  recommendationCard: {
    backgroundColor: whiteColor,
    [theme.breakpoints.down('sm')]: {
      width: '235px',
      height: '275px',
    },
    [theme.breakpoints.up('md')]: {
      width: '235px',
      height: '275px',
    },
    margin: '0, 5px',
  },
  pageHeader: {
    margin: '10px 0 0 0',
  },
  pageTitle: {
    fontSize: '1.438rem',
    textAlign: 'left',
    fontWeight: 600,
  },
  pageIconText: {
    color: whiteColor,
    fontWeight: 600,
    marginLeft: 20,
  },
  printHeaderText: {
    color: '#000',
    fontWeight: 600,
  },
  homeIcon: {
    fontSize: '1.813rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    padding: 30,
  },
  pageDesc: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    padding: '10px 5px 15px 5px',
    color: '#000',
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  breadcrumb: {
    padding: '18px 0 0 10px',
  },
  breadCrumbText: {
    fontSize: '0.80rem',
  },
  breadCrumbLink: {
    fontSize: '0.80rem',
    cursor: 'pointer',
    '&:hover,&:focus': {
      textDecoration: 'underline',
    },
  },
  addIcon: {
    color: webPrimaryColor,
    fontSize: '5.00rem',
    marginTop: 50,
    textAlign: 'center',
  },
  addSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
    marginTop: 15,
  },
  returnNote: {
    fontSize: '1.238rem',
    color: '#FFD700',
    marginBottom: 5,
  },
  noApps: {
    fontSize: '1.088rem',
    marginTop: 50,
  },
  contactCard: {
    height: '180px',
    minHeight: '180px',
    margin: '0, 20px',
  },
  contact: {
    width: '100%',
    position: 'relative',
    top: 180,
  },
  contactInfoLabel: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: webPrimaryColor,
    fontWeight: 600,
  },
  contactInfo: {
    fontSize: '1rem',
    color: webPrimaryColor,
    lineHeight: '1.25rem',
    verticalAlign: 'middle',
    marginRight: 20,
  },
  helpOptionsList: {
    color: webPrimaryColor,
    fontSize: '0.90rem',
    textAlign: 'center',
    fontStyle: 'italic',
  },
  cardIcon: {
    position: 'absolute',
    top: '-55px',
    left: '28%',
  },
  pageIcon: {
    width: '10%',
    marginRight: '15px',
  },
  cardBody: {
    padding: '35px 10px',
  },
  headerCard: {
    marginBottom: 10,
    padding: '20px',
  },
  cardContainer: {
    alignItems: 'center',
  },
  welcomeGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100px',
      alignItems: 'flex-end',
      marginTop: '25px',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      alignItems: 'flex-start',
    },
  },
  adminWelcomeGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100px',
      alignItems: 'flex-end',
      marginTop: '25px',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      alignItems: 'flex-start',
      marginBottom: 30,
    },
  },
  alumniWelcomeGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100px',
      alignItems: 'center',
      marginTop: '25px',
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      alignItems: 'center',
      marginBottom: 100,
    },
  },
  specialistGrid: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      marginTop: '25px',
      borderLeft: `1px solid ${secondaryColor}`,
      padding: '10px 10px 0 20px !important',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
  },
  footerContact: {
    fontSize: '1.00rem',
    color: webPrimaryColor,
    width: '100%',
    marginTop: 20,
  },
  footerContactInfo: {
    color: webPrimaryColor,
    fontWeight: 500,
    fontSize: '1.00rem',
    lineHeight: '1.35rem',
    [theme.breakpoints.up('md')]: {
      padding: '5px 5%',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '5px 0',
    },
  },
  version: {
    color: btMediumGrayColor,
    fontWeight: 500,
    fontSize: '0.80rem',
    lineHeight: '1.00rem',
    [theme.breakpoints.up('md')]: {
      padding: '10px 15% 0 5%',
    },

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '10px 0',
    },
  },
  cardGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px 20px',
    padding: '5% 10% !important',
  },
  footerLink: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '0.90rem',
    lineHeight: '1.10rem',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '5px 0 2px 0',
    },
    [theme.breakpoints.up('md')]: {
      padding: '5px 15%',
    },
  },
  footerTitle: {
    textTransform: 'uppercase',
    fontSize: '1.20rem',
    fontWeight: 400,
    lineHeight: '1.649rem',
    color: webSecondaryColor,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    [theme.breakpoints.up('md')]: {
      padding: '5px 5%',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '15px 0 5px 0',
    },
  },
  footerMargin: {
    padding: '0 10% 0 10%',
  },
  applicationArea: {
    fontSize: '1.00rem',
    lineHeight: '1.25rem',
    color: webSecondaryColor,
  },
  warning: {
    backgroundColor: '#fdb515',
    color: 'black',
  },
}));

const SeniorPlanner = inject(
  'SeniorStore',
  'AuthStore',
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const classes = useStyles();
    const history = useHistory();

    const [year, setYear] = useState('');
    const [page, setPage] = useState('home');
    const [showHelp, setShowHelp] = useState(false);
    const [showReturnedChecklist, setShowReturnedChecklist] = useState(false);
    const [showReturnedPlanningForm1, setShowReturnedPlanningForm1] = useState(false);
    const [showReturnedPlanningForm2, setShowReturnedPlanningForm2] = useState(false);
    const [checklistStatus, setChecklistStatus] = useState('');
    const [planningForm1Status, setPlanningForm1Status] = useState('');
    const [planningForm2Status, setPlanningForm2Status] = useState('');
    const [showReturnedAftercareApp, setShowReturnedAftercareApp] = useState(false);
    const [showReturnedScholarshipApp, setShowReturnedScholarshipApp] = useState(false);
    const [aftercareSpecialist, setAftercareSpecialist] = useState(null);
    const [warning, setWarning] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
      setYear(new Date().getFullYear());
      return () => {
        store.setSenior(null);
        store.setBasicInfoId(null);
      };
    }, []);

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    useEffect(() => {
      let checklistdata = {};
      let form1data = {};
      let form2data = {};

      const fetchAftercareSpecialistList = async () => {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const specialists = await callApi(auth, 'staff', 'GET');
          store.setAftercareSpecialistList(
            specialists.result.filter(
              (s) =>
                s.is_active &&
                (s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534'),
            ),
          );
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching aftercare specialist list from SeniorPlanner:', error);
          logException(error);
        }
      };

      if (store.senior && store.senior.id > 0) {
        if (store.senior.aftercare_specialist > 0) {
          if (store.aftercareSpecialistList && store.aftercareSpecialistList.length > 0) {
            const spec = getRowFromListById(store.aftercareSpecialistList, store.senior.aftercare_specialist);
            if (spec) {
              setAftercareSpecialist(spec);
            } else {
              if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
                setWarning('The assigned Successful Futures Specialist was not found. Please assign a valid specialist.');
                setShowWarning(true);
              }
            }
          }
        } else {
          if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
            setWarning('No Successful Futures Specialist has been assigned. Please assign a valid specialist.');
            setShowWarning(true);
          }
        }
      }

      async function fetchScholarshipApps(userId) {
        try {
          let scholarshipApps = await callApi(auth, 'scholarship/list/' + userId, 'GET');
          store.setScholarshipList(scholarshipApps.result);
          scholarshipApps.result.forEach((app) => {
            if (app.status === 'returned') {
              setShowReturnedScholarshipApp(true);
            }
          });
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching scholarship apps:', error);
          logException(error);
        }
      }

      async function fetchAftercareApps(userId) {
        try {
          let applications = await callApi(auth, 'aftercare/list/' + userId, 'GET');
          store.setAftercareList(applications.result);
          applications.result.forEach((app) => {
            if (app.status === 'returned') {
              setShowReturnedAftercareApp(true);
            }
          });
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching aftercare apps:', error);
          logException(error);
        }
      }

      async function fetchChecklist(id) {
        try {
          const checklist = await callApi(auth, 'checklist/' + id, 'GET');
          if (checklist.result) {
            setShowReturnedChecklist(false);
            store.setChecklistObj(checklist.result);
            checklistdata = JSON.parse(checklist.result.checklist_data);
            const status = checklistdata.status;
            setChecklistStatus(status);
            if (status === 'returned') {
              setShowReturnedChecklist(true);
            }
          }
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching checklist:', error);
          logException(error);
        }
      }

      async function fetchPlanningForm1(id) {
        try {
          const planningform1 = await callApi(auth, 'planningform1/' + store.senior.id, 'GET');
          if (planningform1.result) {
            setShowReturnedPlanningForm1(false);
            store.setPlanform1Obj(planningform1.result);
            form1data = JSON.parse(planningform1.result.planning_form_1_data);
            const status = form1data.status;
            setPlanningForm1Status(status);
            if (status === 'returned') {
              setShowReturnedPlanningForm1(true);
            }
          }
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching checklist:', error);
          logException(error);
        }
      }

      async function fetchPlanningForm2(id) {
        try {
          const planningform2 = await callApi(auth, 'planningform2/' + store.senior.id, 'GET');
          if (planningform2.result) {
            setShowReturnedPlanningForm2(false);
            store.setPlanform2Obj(planningform2.result);
            form2data = JSON.parse(planningform2.result.planning_form_2_data);
            const status = form2data.status;
            setPlanningForm2Status(status);
            if (status === 'returned') {
              setShowReturnedPlanningForm2(true);
            }
          }
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching checklist:', error);
          logException(error);
        }
      }

      async function fetchSenior() {
        try {
          if (!store.senior || (store.senior && Object.keys(store.senior).length === 0)) {
            store.setLoading(true);
            store.setSearching(true);
            let senior = {};

            if (store.basicInfoId > 0) {
              senior = await callApi(auth, 'student/' + store.basicInfoId, 'GET');
              console.log('.AAAAAA.');
            } else if (auth && auth.user && auth.user.result) {
              senior = await callApi(auth, 'studentplan/' + auth.user.result.id, 'GET');
            }
            if (senior.result) store.setSenior(senior.result);
            store.setSearching(false);
            store.setLoading(false);
          }
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.error('Error fetching senior:', error);
          logException(error);
        }
      }

      fetchAftercareSpecialistList();
      fetchSenior();

      if (store.senior && Object.keys(store.senior).length > 0) {
        fetchChecklist(store.senior.id);
        fetchPlanningForm1(store.senior.id);
        fetchPlanningForm2(store.senior.id);
        fetchAftercareApps(store.senior.id);
        fetchScholarshipApps(store.senior.id);

        const thisSenior = store.senior;
        thisSenior.checklist = checklistdata;
        thisSenior.planning_form_1 = form1data;
        thisSenior.planning_form_2 = form2data;
        store.setSenior(thisSenior);
      }
    }, [store, auth, store.senior, auth.user]);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowWarning(false);
    };

    const handleReturnToSeniorList = () => {
      store.setSenior(null);
      if (store.alumni) {
        history.push('/alumnilist');
      } else {
        history.push('/seniorlist');
      }
    };

    const handleAfterSaveReturn = () => {
      setShowReturnedAftercareApp(false);
      window.scrollTo(0, 0);
      store.aftercareList.forEach((app) => {
        if (app.status === 'returned') {
          setShowReturnedAftercareApp(true);
        }
      });

      let tempShowReturnedScholarshipApp = false;
      store.scholarshipList.forEach((app) => {
        if (app.status === 'returned') {
          tempShowReturnedScholarshipApp = true;
        }
      });
      setShowReturnedScholarshipApp(tempShowReturnedScholarshipApp);

      setShowReturnedChecklist(false);
      if (checklistStatus === 'returned') {
        setShowReturnedChecklist(true);
      }

      setShowReturnedPlanningForm1(false);
      if (planningForm1Status === 'returned') {
        setShowReturnedPlanningForm1(true);
      }

      setShowReturnedPlanningForm2(false);
      if (planningForm2Status === 'reopened') {
        setShowReturnedPlanningForm2(true);
      }

      setPage('home');
    };

    const handleAfterAboutMeUpdate = (newSeniorData) => {
      store.setSenior(newSeniorData);
      setPage('recommendationpage');
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const openAftercareApp = async (id) => {
      store.setAftercareAppId(id);
      try {
        store.setLoading(true);
        store.setSearching(true);
        let application = await callApi(auth, 'aftercare/' + id, 'GET');
        if (application.result) store.setAftercareApp(application.result);
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error fetching senior:', error);
      }
      setPage('aftercareapp');
    };

    const handleCreateAftercareApp = async () => {
      store.setAppCreated(true);
      try {
        store.setLoading(false);
        store.setSearching(true);
        const app = await callApi(auth, 'aftercare', 'POST', {
          item: {
            userId: store.senior.userId,
            status: 'created',
            status_date: new Date(),
            status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          },
        });

        if (app.result) {
          store.setAftercareApp(app.result);
          store.setAftercareAppId(app.result.id);
        }
        store.setSearching(false);
        store.setLoading(false);
        openAftercareApp(app.result.id);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error writing updated Successful Futures application:', error);
      }
    };

    const fetchAftercareApplications = async () => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        let applications = await callApi(auth, 'aftercare/list/' + store.senior.userId, 'GET');
        if (applications.result) store.setAftercareList(applications.result);
        store.setSearching(false);
        store.setLoading(false);
        setPage('aftercareapps');
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    };

    const openScholarshipApp = async (id) => {
      store.setScholarshipAppId(id);
      try {
        store.setLoading(true);
        store.setSearching(true);
        let scholarship = store.scholarshipList.find((s) => s.id === id);
        store.setScholarshipApp(scholarship);
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error locating scholarship app:', error);
      }
      setPage('scholarshipapp');
    };

    const openRecommendation = async (id) => {
      store.setRecommendationId(id);
      try {
        store.setLoading(true);
        store.setSearching(true);
        // let recommendations = await callApi(auth, 'recommendation/', 'GET');
        let recommendations = await callApi(auth, 'recommendation/student/' + store.senior.id, 'GET');
        if (recommendations.result) {
          let letter = recommendations.result.find((s) => s.recommendation_id === id);
          store.setRecommendation(letter);
        }
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error locating letter of recommendation request:', error);
      }
      setPage('recommendationpage');
    };

    const openHelpApp = async (id) => {
      store.setHelpAppId(id);
      try {
        store.setLoading(true);
        store.setSearching(true);
        let app = store.helpAppList.find((s) => s.id === id);
        store.setHelpApp(app);
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error locating help request app:', error);
      }
      setPage('helpapp');
    };

    const summarizeHelpOptions = (app) => {
      if (!app || (app && app.length === 0)) return [];
      let list = [];
      const appValues = JSON.parse(app);
      if (appValues.coaching) {
        list.push('Coaching');
      }
      if (appValues.dailyLiving) {
        list.push('Daily Living');
      }
      if (appValues.education) {
        list.push('Education');
      }
      if (appValues.employment) {
        list.push('Employment');
      }
      if (appValues.finances) {
        list.push('Finances');
      }
      if (appValues.food) {
        list.push('Food');
      }
      if (appValues.health) {
        list.push('Health');
      }
      if (appValues.housing) {
        list.push('Housing');
      }
      if (appValues.legalDocs) {
        list.push('Legal Documents');
      }
      if (appValues.lifeSkills) {
        list.push('Life Skills');
      }
      if (appValues.mentalHealth) {
        list.push('Mental Health');
      }
      if (appValues.scholarships) {
        list.push('Scholarships');
      }
      if (appValues.transportation) {
        list.push('Transportation');
      }
      if (appValues.utilities) {
        list.push('Utilities');
      }
      if (appValues.other) {
        list.push('Other');
      }
      return list;
    };

    const displayStatus = (status) => {
      if (status === 'created') {
        return 'Created';
      }
      if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
        return 'Under Review';
      }
      if (status === 'approved') {
        return 'Processed';
      }
      if (status === 'awarded') {
        return 'Processed';
      }
      if (status === 'ApprovedAlternate') {
        return 'Processed';
      }
      if (status === 'denied') {
        return 'Processed';
      }
      if (status === 'new') {
        return 'New';
      }
      if (status === 'inprogress') {
        return 'In Progress';
      }
      if (status === 'closed') {
        return 'Closed';
      }
      return status;
    };

    const formatPhoneNumber = (str) => {
      //Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }

      return null;
    };

    const handleCreateHelpApp = async () => {
      store.setAppCreated(true);
      try {
        store.setLoading(false);
        store.setSearching(true);
        const app = await callApi(auth, 'helprequest', 'POST', {
          item: {
            userId: store.senior.userId,
            created_at: new Date(),
            updated_at: new Date(),
            status: 'created',
            status_date: new Date(),
            status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          },
        });

        if (app.result) {
          store.setHelpAppList([...store.helpAppList, app.result]);
          store.setHelpApp(app.result);
        }
        store.setSearching(false);
        store.setLoading(false);
        openHelpApp(app.result.id);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error creating initial Help request application:', error);
      }
    };

    const fetchHelpApplications = async () => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        let applications = await callApi(auth, 'helprequest/list/' + store.senior.userId, 'GET');
        if (applications.result) store.setHelpAppList(applications.result);
        store.setSearching(false);
        store.setLoading(false);
        setPage('helpapps');
      } catch (err) {
        logException(err);
        store.setLoading(false);
        console.error(err);
      }
    };

    const handleCreateScholarshipApp = async () => {
      store.setAppCreated(true);
      try {
        store.setLoading(false);
        store.setSearching(true);
        const app = await callApi(auth, 'scholarship', 'POST', {
          item: {
            userId: store.senior.userId,
            status: 'created',
            status_date: new Date(),
            status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
            applicant_type: auth.roles.includes('ALUMNI') ? 'alumni' : 'senior',
          },
        });

        if (app.result) {
          store.setScholarshipList([...store.scholarshipList, app.result]);
          store.setScholarshipApp(app.result);
        }
        store.setSearching(false);
        store.setLoading(false);
        openScholarshipApp(app.result.id);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.error('Error creating initial scholarship application:', error);
      }
    };

    const fetchScholarshipApplications = async () => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        let applications = await callApi(auth, 'scholarship/list/' + store.senior.userId, 'GET');
        if (applications.result) store.setScholarshipList(applications.result);
        store.setSearching(false);
        store.setLoading(false);
        setPage('scholarshipapps');
      } catch (err) {
        logException(err);
        store.setLoading(false);
        console.error(err);
      }
    };

    const handleCreateRecommendationRequest = async () => {
      store.setNewRecommendation(true);
      setPage('recommendationpage');
    };

    // const fetchRecommendations = async () => {
    //   try {
    //     store.setLoading(true);
    //     store.setSearching(true);
    //     let recommendations = await callApi(auth, 'recommendation/', 'GET');
    //     store.setRecommendationList(recommendations.result);
    //     store.setSearching(false);
    //     store.setLoading(false);
    //     setPage('recommendationlist');
    //   } catch (err) {
    //     logException(err);
    //     store.setLoading(false);
    //     console.error(err);
    //   }
    // };

    const fetchRecommendationsForStudent = async () => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        let recommendations = await callApi(auth, 'recommendation/student/' + store.senior.id, 'GET');
        if (recommendations.result) store.setRecommendationList(recommendations.result);
        store.setSearching(false);
        store.setLoading(false);
        setPage('recommendationlist');
      } catch (err) {
        logException(err);
        store.setLoading(false);
        console.error(err);
      }
    };

    const handleHelpClose = () => {
      setShowHelp(false);
    };

    const handleHelp = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowHelp(true);
    };

    const countOpenApplications = () => {
      let totalOpen = 0;
      store.aftercareList.forEach((app) => {
        if (app.status !== 'approved' && app.status !== 'denied') {
          totalOpen += 1;
        }
      });
      return totalOpen;
    };

    const navToAftercareApps = () => {
      if (store.senior.userId) {
        fetchAftercareApplications();
      }
    };

    const navToScholarshipApps = () => {
      if (store.senior.userId) {
        fetchScholarshipApplications();
      }
    };

    const navToRecommendations = () => {
      if (store && store.senior && store.senior.userId) {
        // if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        //   fetchRecommendations();
        // } else {
        fetchRecommendationsForStudent();
        // }
      }
    };

    const navToCheckInApps = () => {
      if (store && store.senior && store.senior.userId) {
        store.setCheckInId(store.senior.id);
        history.push('/contactlist');
      }
    };

    const navToHelpApps = () => {
      if (store.senior.userId) {
        fetchHelpApplications();
      }
    };

    return (
      <React.Fragment>
        {page === 'home' && (
          <Home
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            formatPhoneNumber={formatPhoneNumber}
            showReturnedAftercareApp={showReturnedAftercareApp}
            showReturnedScholarshipApp={showReturnedScholarshipApp}
            aftercareSpecialist={aftercareSpecialist}
            showReturnedChecklist={showReturnedChecklist}
            showReturnedPlanningForm1={showReturnedPlanningForm1}
            showReturnedPlanningForm2={showReturnedPlanningForm2}
            setPage={setPage}
            navToHelpApps={navToHelpApps}
            navToAftercareApps={navToAftercareApps}
            navToScholarshipApps={navToScholarshipApps}
            navToRecommendations={navToRecommendations}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
            navToCheckInApps={navToCheckInApps}
          />
        )}

        {page === 'helpapps' && (
          <HelpApps
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            helpAppList={store.helpAppList}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            handleCreateHelpApp={handleCreateHelpApp}
            openHelpApp={openHelpApp}
            summarizeHelpOptions={summarizeHelpOptions}
            displayStatus={displayStatus}
            classes={classes}
          />
        )}

        {page === 'aftercareapps' && (
          <AftercareApps
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            aftercareSpecialist={aftercareSpecialist}
            aftercareList={store.aftercareList}
            countOpenApplications={countOpenApplications}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            displayStatus={displayStatus}
            formatPhoneNumber={formatPhoneNumber}
            handleCreateAftercareApp={handleCreateAftercareApp}
            openAftercareApp={openAftercareApp}
            classes={classes}
          />
        )}

        {page === 'scholarshipapps' && (
          <ScholarshipApps
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            aftercareSpecialist={aftercareSpecialist}
            formatPhoneNumber={formatPhoneNumber}
            handleCreateScholarshipApp={handleCreateScholarshipApp}
            showReturnedScholarshipApp={showReturnedScholarshipApp}
            scholarshipList={store.scholarshipList}
            openScholarshipApp={openScholarshipApp}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            displayStatus={displayStatus}
            classes={classes}
          />
        )}

        {/* {page === 'recommendationlist' && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && ( */}
        {page === 'recommendationlist' && (
          <Recommendations
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            formatPhoneNumber={formatPhoneNumber}
            recommendationList={store.recommendationList}
            handleCreateRecommendationRequest={handleCreateRecommendationRequest}
            openRecommendation={openRecommendation}
            displayStatus={displayStatus}
            aftercareSpecialist={aftercareSpecialist}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'basicinfo' && (
          <BasicInfo
            alumni={store.alumni}
            roles={auth.roles}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {(store.alumni || auth.roles.includes('ALUMNI')) && page === 'helpapp' && (
          <HelpApp
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'planning1' && (
          <Planning1
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'planning2' && (
          <Planning2
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {!auth.roles.includes('ALUMNI') && page === 'checklist' && (
          <Checklist
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'aftercareapp' && (
          <Aftercare
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'scholarshipapp' && (
          <Scholarship
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            classes={classes}
          />
        )}

        {page === 'recommendationpage' && (
          <Recommendation
            senior={store.senior}
            alumni={store.alumni}
            roles={auth.roles}
            handleHelp={handleHelp}
            store={store}
            auth={auth}
            handleAfterSaveReturn={handleAfterSaveReturn}
            setPage={setPage}
            handleReturnToSeniorList={handleReturnToSeniorList}
            fetchRecommendations={fetchRecommendationsForStudent}
            classes={classes}
            handleAfterAboutMeUpdate={handleAfterAboutMeUpdate}
          />
        )}

        {!auth.roles.includes('ALUMNI') && page === 'tips' && <Tips setPage={setPage} classes={classes} />}

        {!auth.roles.includes('ALUMNI') && page === 'life' && <Life setPage={setPage} classes={classes} />}

        <HelpDialog
          title="Printer Tips & Tricks"
          content={
            <React.Fragment>
              <Grid container direction="row" style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                  When the print menu opens, choose the destination (such as a specific printer or PDF file) on the top
                  right side of the window.
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginBottom: 15 }}>
                <Grid item xs={12}>
                  To improve the look of your printed copies, click on "More settings" on the right side and change the
                  "Margins" setting to "Minimum"
                  or "Custom." This will add a margin around the contents, so the page break doesn't happen in the
                  middle of a line.
                </Grid>
              </Grid>
            </React.Fragment>
          }
          showHelp={showHelp}
          handleClose={handleHelpClose}
        />
        {page === 'home' && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && store.senior && (
          <footer className={classes.footerContact} style={{ width: '100%', marginTop: '100px' }}>
            <Grid container className={classes.footerMargin}>
              <Grid item xs={false} md={2} lg={2} />
              <Grid item xs={12} md={4} lg={4}>
                <a href="/home">
                  <img src={BoysTownLogo} alt="Boys Town" style={{ width: '50%' }} />
                </a>
                <div className={classes.footerContact}>
                  <div className={classes.footerContactInfo}>
                    <div>&copy; {year} Boys Town. All Rights Reserved.</div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={false} md={1} lg={1} />
              <Grid item xs={12} md={3} lg={3} align="left">
                <div className={classes.footerTitle}>CONTACT US</div>
                <div className={classes.footerContactInfo}>
                  <div>
                    <a href="mailto:successfulfutures@boystown.org">SuccessfulFutures@Boystown.org</a>
                  </div>
                  <div>531-355-3060</div>
                </div>
                <div className={classes.version}>Version 1.2.0</div>
              </Grid>
              <Grid item xs={false} md={2} lg={2} />
            </Grid>
          </footer>
        )}
        <Snackbar
          ContentProps={{
            classes: {
              root: classes.warning,
            },
          }}
          open={showWarning}
          autoHideDuration={6000}
          onClose={handleClose}
          message={warning}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ></Snackbar>
      </React.Fragment>
    );
  }),
);

export default SeniorPlanner;
