/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { msalConfig } from '../../services/msal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BoysTownLogo from '../../assets/img/bt.jpg';

import styles from '../../assets/jss/nextjs-material-kit-pro/components/footerStyle.js';

const useStyles = makeStyles(styles);

const Footer = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { theme } = props;
    const classes = useStyles();
    const themeType = theme === 'transparent' || theme == undefined ? false : true;
    const footerClasses = classNames({
      [classes.footer]: true,
      [classes[theme]]: themeType,
    });

    const [year, setYear] = useState('');

    useEffect(() => {
      setYear(new Date().getFullYear());
    }, []);

    useEffect(() => {
      const handleLogin = async () => {
        await authStore.handleUserLogin(accounts[0]);
      };
      if (isAuthenticated && authStore.user == null) {
        handleLogin();
        // window.location.href = '/';
      }
    }, [accounts, authStore, isAuthenticated]);

    const handleSignIn = async () => {
      await instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      const account = accounts[0] || null;
      authStore.handleUserLogin(account);
    };

    return (
      <footer className={footerClasses} style={{ width: '100%', position: 'absolute', bottom: 10 }}>
        <Grid container>
          <Grid item xs={false} md={1} lg={1} />
          <Grid item xs={12} md={4} lg={4}>
            <a href="/home">
              <img src={BoysTownLogo} alt="Boys Town" style={{ width: '50%' }} />
            </a>
            <div className={classes.contact}>
              <div className={classes.contactInfo}>
                <div>&copy; {year} Boys Town. All Rights Reserved.</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={2} align="left">
            <div className={classes.footerTitle}>LINKS</div>
            <div className={classes.footerLink}>
              <a href="/help">HOW CAN WE HELP?</a>
            </div>
            <div className={classes.footerLink}>
              <a href="/alumni">ALUMNI ASSOCIATION</a>
            </div>
            <div className={classes.footerLink} onClick={() => handleSignIn()}>
              SIGN IN
            </div>
            <div className={classes.footerLink}>
              <a href="https://www.boystown.org/privacy-center" target="_blank">
                PRIVACY CENTER
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3} align="left">
            <div className={classes.footerTitle}>CONTACT US</div>
            <div className={classes.contactInfo}>
              <div>
                <a href="mailto:successfulfutures@boystown.org">SuccessfulFutures@Boystown.org</a>
              </div>
              <div>531-355-3060</div>
            </div>
            <div className={classes.version}>Version 1.2.0</div>
          </Grid>
          <Grid item xs={false} md={1} lg={2} />
        </Grid>
      </footer>
    );
  })
);

Footer.propTypes = {
  theme: PropTypes.oneOf(['dark', 'white', 'transparent']),
};

export default Footer;
