import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import PleaseWait from '../Shared/PleaseWait';
import EditIcon from '@material-ui/icons/Edit';
import Card from '../Card/Card.js';
import CardBody from '../Card/CardBody.js';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { logException } from '../../services/appInsights';
import loginPageStyle from '../../assets/jss/nextjs-material-kit-pro/pages/loginPageStyle.js';
import { callApi } from '../../util/apiWrapper';
import { convertSiteCode } from '../../util/miscUtil';
import PhoneDialog from './DialogComponents/PhoneDialog';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import useAuthRedirect from '../../hooks/authRedirectHook';

const pageStyles = makeStyles(loginPageStyle);

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.up('md')]: {
      height: '180vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      height: '225vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  welcome: {
    margin: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.95rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.55rem',
    },
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.50em 2.00em',
  },
  menuTitle: {
    fontSize: 24,
    textAlign: 'left',
    margin: '10px 0',
    color: 'blue',
  },
  menuDesc: {
    fontSize: 16,
    textAlign: 'left',
    padding: '0 5px 15px 0',
  },
  statLink: {
    fontSize: '0.80rem',
    color: 'blue',
    cursor: 'pointer',
  },
  statName: {
    fontSize: '0.80rem',
  },
  statValue: {
    fontSize: '0.80rem',
    fontWeight: 500,
  },
  progressMeter: {
    padding: '1%',
    fontSize: '20px',
    height: '30px',
    color: '#fff',
    width: '95%',
  },
  cardSize: {
    maxWidth: '400px',
    height: '300px',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  phoneButton: {
    paddingLeft: 20,
  },
  editButton: {
    fontSize: '1.10rem',
    paddingBottom: 3,
  },
  space20: {
    marginLeft: 20,
  },
}));

const Index = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;
    const history = useHistory();

    const classes = useStyles();
    const pageClasses = pageStyles();

    const [year, setYear] = useState('');
    const [totalSeniors, setTotalSeniors] = useState(0);
    const [activeSeniors, setActiveSeniors] = useState(0);
    // const [seniorsToBeOnboarded, setseniorsToBeOnboarded] = useState(0);
    const [studentsSubmittedScholarshipApps, setStudentsSubmittedScholarshipApps] = useState(0);
    const [studentsAppliedAftercareTradelife, setStudentsAppliedAftercareTradelife] = useState(0);
    const [studentsAppliedAftercareHousing, setStudentsAppliedAftercareHousing] = useState(0);

    const [tradelifeApps, setTradelifeApps] = useState(0);
    const [housingApps, setHousingApps] = useState(0);
    const [tradelifeSubmittedApps, setTradelifeSubmittedApps] = useState(0);
    const [tradelifeInterviewApps, setTradelifeInterviewApps] = useState(0);
    // const [tradelifeCompletedApps, setTradelifeCompletedApps] = useState(0);
    const [housingSubmittedApps, setHousingSubmittedApps] = useState(0);
    const [housingInterviewApps, setHousingInterviewApps] = useState(0);
    // const [housingCompletedApps, setHousingCompletedApps] = useState(0);

    const [totalScholarshipApplications, setTotalScholarshipApplications] = useState(0);
    // const [newScholarshipApplications, setNewScholarshipApplications] = useState(0);
    // const [interviewScholarshipApplications, setInterviewScholarshipApplications] = useState(0);
    const [qualifiedScholarshipApplications, setQualifiedScholarshipApplications] = useState(0);
    // const [completedScholarshipApplications, setCompletedScholarshipApplications] = useState(0);

    const [totalPendingHelpApplications, setTotalPendingHelpApplications] = useState(0);
    const [totalClosedHelpApplications, setTotalClosedHelpApplications] = useState(0);

    const [totalPendingContacts, setTotalPendingContacts] = useState(0);
    const [totalClosedContacts, setTotalClosedContacts] = useState(0);

    const [totalAlumni, setTotalAlumni] = useState(0);
    const [accessRequests, setAccessRequests] = useState(0);
    // const [approvedAccessRequests, setApprovedAccessRequests] = useState(0);
    const [deniedAccessRequests, setDeniedAccessRequests] = useState(0);
    // const [alumniScholarshipApplications, setAlumniScholarshipApplications] = useState(0);
    // const [alumniHousingApplications, setAlumniHousingApplications] = useState(0);

    const [showDialog, setShowDialog] = useState(false);

    const [staff, setStaff] = useState(null);
    const [warning, setWarning] = useState(null);

    useEffect(() => {
      setYear(new Date().getFullYear());
    }, []);

    useEffect(() => {
      async function fetchStaffSites() {
        try {
          if (store.staffsites.length === 0) {
            store.setLoading(true);
            store.setSearching(true);
            const email = auth.user.result.email;
            const sites = await callApi(auth, 'staff/sites/' + email, 'GET');
            let siteList = [];
            sites.result.forEach((s, i) => {
              // if (auth.user.result.email === 'tamara.raridon@boystown.org') {
              //   if (s.node_id === 'LA' || s.node_id === 'NV' || s.node_id === 'SFLA' || s.node_id === 'CFLA' || s.node_id === 'NFLA') {
              siteList.push(convertSiteCode(s.node_id));
              //   }
              // }
            });
            console.warn('Site permissions for staff user fetched: ', siteList);
            store.setStaffSites(siteList);
            store.setSearching(false);
            store.setLoading(false);
          }
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching staff sites:', error);
        }
      }
      setYear(new Date().getFullYear());
      const fetchAftercareSpecialist = async (specId) => {
        if (!specId) return;
        try {
          store.setLoading(true);
          store.setSearching(true);
          const specialist = await callApi(auth, 'staff/user/' + specId, 'GET');
          if (
            (specialist.result.position_class === '1347' ||
              specialist.result.position_class === '2001' ||
              specialist.result.position_class === '2148' ||
              specialist.result.position_class === '1534') &&
            (!specialist.result.office_phone || specialist.result.office_phone.length === 0)
          ) {
            setWarning('Your office phone number is not set up. Please add your phone numbers.');
            setShowDialog(true);
          }
          setStaff(specialist.result);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching aftercare specialist:', error);
          logException(error);
        }
      };
      async function fetchStudentStats() {
        try {
          store.setSearching(true);
          store.setLoading(true);
          const stats = await callApi(auth, `student/dashboard?siteList=${store.staffsites}`, 'GET');
          setTotalSeniors(stats.totalSeniors);
          setActiveSeniors(stats.activeSeniors);
          // setseniorsToBeOnboarded(stats.seniorsToBeOnboarded);
          setStudentsSubmittedScholarshipApps(stats.studentsSubmittedScholarshipApps);
          setStudentsAppliedAftercareTradelife(stats.studentsAppliedAftercareTradelife);
          setStudentsAppliedAftercareHousing(stats.studentsAppliedAftercareHousing);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching student stats:', error);
        }
      }
      async function fetchAlumniStats() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const alumniStats = await callApi(auth, `alumni/dashboard?siteList=${store.staffsites}`, 'GET');
          setTotalAlumni(alumniStats.totalAlumni);
          setAccessRequests(alumniStats.pendingAccessRequests);
          // setApprovedAccessRequests(alumniStats.approvedAccessRequests);
          setDeniedAccessRequests(alumniStats.deniedAccessRequests);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching alumni stats:', error);
        }
      }
      async function fetchAftercareStats() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const aftercareStats = await callApi(auth, `aftercare/dashboard?siteList=${store.staffsites}`, 'GET');
          setTradelifeApps(aftercareStats.tradelifeApps);
          setTradelifeSubmittedApps(aftercareStats.tradelifeSubmittedApps);
          setTradelifeInterviewApps(aftercareStats.tradelifeInterviewApps);
          // setTradelifeCompletedApps(aftercareStats.tradelifeClosedApps);
          setHousingApps(aftercareStats.housingApps);
          setHousingSubmittedApps(aftercareStats.housingSubmittedApps);
          setHousingInterviewApps(aftercareStats.housingInterviewApps);
          // setHousingCompletedApps(aftercareStats.housingClosedApps);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching aftercare stats:', error);
          // todo handle error
        }
      }
      async function fetchScholarshipStats() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const scholarshipStats = await callApi(auth, `scholarship/dashboard?siteList=${store.staffsites}`, 'GET');
          setTotalScholarshipApplications(scholarshipStats.pendingApps);
          // setNewScholarshipApplications(scholarshipStats.newApps);
          // setInterviewScholarshipApplications(scholarshipStats.interviewApps);
          setQualifiedScholarshipApplications(scholarshipStats.qualifiedApps);
          // setCompletedScholarshipApplications(scholarshipStats.completedApps);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching scholarship stats:', error);
          // todo handle error
        }
      }
      async function fetchHelpStats() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const helpRequestStats = await callApi(auth, `helprequest/dashboard`, 'GET');
          setTotalPendingHelpApplications(helpRequestStats.pendingApps);
          setTotalClosedHelpApplications(helpRequestStats.closedApps);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching help request stats:', error);
          // todo handle error
        }
      }
      async function fetchContactStats() {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const contactStats = await callApi(auth, `contact/dashboard`, 'GET');
          setTotalPendingContacts(contactStats.pendingContacts);
          setTotalClosedContacts(contactStats.closedContacts);
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching contact stats:', error);
          // todo handle error
        }
      }
      const fetchAftercareSpecialistList = async () => {
        try {
          store.setLoading(true);
          store.setSearching(true);
          const specialists = await callApi(auth, 'staff', 'GET');
          store.setAftercareSpecialistList(
            specialists.result.filter(
              (s) =>
                s.is_active &&
                (s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534')
            )
          );
          store.setSearching(false);
          store.setLoading(false);
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.warn('Error fetching aftercare specialist list:', error);
          logException(error);
        }
      };

      if (auth && auth.authToken && auth.authToken.length > 0) {
        fetchStaffSites();
        fetchStudentStats();
        fetchAlumniStats();
        fetchAftercareStats();
        fetchScholarshipStats();
        fetchHelpStats();
        fetchContactStats();
        fetchAftercareSpecialistList();
        if (auth?.user?.result?.roles?.includes('STUDENT')) {
          fetchAftercareSpecialist(auth.user.result?.aftercare_specialist);
        }
      }
    }, [auth, auth.user, store, store.staffsites]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, store, history]);

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    const handleViewScholarshipApps = () => {
      history.push('/scholarshiplist');
    };

    const handleViewHelpApps = () => {
      history.push('/helprequestlist');
    };

    const handleViewContacts = () => {
      store.setCheckInId(null);
      history.push('/contactlist');
    };

    const handleViewSeniorList = () => {
      history.push('/seniorlist');
    };

    const handleViewAftercareApps = () => {
      store.setAftercareServiceTypeFilter('');
      store.setAftercareStatusFilter('');
      history.push('/aftercarelist');
    };

    const handleViewTradelifeAftercareApps = () => {
      store.setAftercareServiceTypeFilter('tradelife');
      store.setAftercareStatusFilter('');
      history.push('/aftercarelist');
    };

    const handleViewHousingAftercareApps = () => {
      store.setAftercareServiceTypeFilter('housing');
      store.setAftercareStatusFilter('');
      history.push('/aftercarelist');
    };

    const handleViewTradelifeNewApps = () => {
      store.setAftercareServiceTypeFilter('tradelife');
      store.setAftercareStatusFilter('new');
      history.push('/aftercarelist');
    };

    const handleViewHousingNewApps = () => {
      store.setAftercareServiceTypeFilter('housing');
      store.setAftercareStatusFilter('new');
      history.push('/aftercarelist');
    };

    const handleViewTradelifeInterviewApps = () => {
      store.setAftercareServiceTypeFilter('tradelife');
      store.setAftercareStatusFilter('interview');
      history.push('/aftercarelist');
    };

    const handleViewTradelifeCompletedApps = () => {
      store.setAftercareServiceTypeFilter('tradelife');
      store.setAftercareStatusFilter('completed');
      history.push('/aftercarelist');
    };

    const handleViewHousingInterviewApps = () => {
      store.setAftercareServiceTypeFilter('housing');
      store.setAftercareStatusFilter('interview');
      history.push('/aftercarelist');
    };

    const handleViewHousingCompletedApps = () => {
      store.setAftercareServiceTypeFilter('housing');
      store.setAftercareStatusFilter('completed');
      history.push('/aftercarelist');
    };

    const handleViewAlumniList = () => {
      history.push('/alumnilist');
    };

    const handleViewAccessRequests = () => {
      history.push('/accessrequestlist');
    };

    const showUpdateAdminPhoneDialog = () => {
      setShowDialog(true);
    };

    const handleDialogClose = () => {
      setShowDialog(false);
    };

    const handleSavePhone = async (officePhone, cellPhone) => {
      if (!staff) return;
      if (!officePhone) return;

      staff.office_phone = officePhone;
      staff.cell_phone = cellPhone;

      const updatedStaff = await callApi(auth, 'staff', 'PUT', {
        item: staff,
      });
      setStaff(updatedStaff.result);
      setWarning('');
      setShowDialog(false);
    };

    const formatPhoneNumber = (str) => {
      //Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }

      return null;
    };

    return (
      <div className={classes.menu}>
        <PleaseWait isLoading={store.loading} />
        {auth.roles && auth.roles.length > 0 && !auth.notAuthorized && !auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
          <React.Fragment>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={false} md={3} lg={3} align="left"></Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" align="center" className={classes.welcome}>
                  Welcome to Boys Town Successful Futures
                </Typography>
                {staff &&
                  staff.id &&
                  staff.id > 0 &&
                  (staff.position_class === '1347' ||
                    staff.position_class === '2001' ||
                    staff.position_class === '2148' ||
                    staff.position_class === '1534') && (
                    <aside
                      style={{
                        color:
                          (staff.position_class === '1347' ||
                            staff.position_class === '2001' ||
                            staff.position_class === '2148' ||
                            staff.position_class === '1534') &&
                          (!staff.office_phone || staff.office_phone.length === 0)
                            ? '#FA0000'
                            : btNavyBlueColor,
                      }}
                    >
                      <label>Office Phone</label> <span className={classes.contactInfo}>{formatPhoneNumber(staff.office_phone) || 'N/A'}</span>{' '}
                      <label className={classes.space20}>Cell Phone</label>{' '}
                      <span className={classes.contactInfo}>{formatPhoneNumber(staff.cell_phone) || 'N/A'}</span>{' '}
                      <Tooltip title="Click to update your phone numbers." placement="bottom">
                        <IconButton size="small" aria-label="delete" color="primary" onClick={(event) => showUpdateAdminPhoneDialog()}>
                          <EditIcon className={classes.editButton} />
                        </IconButton>
                      </Tooltip>
                    </aside>
                  )}
              </Grid>
              <Grid item xs={false} md={3} lg={3}></Grid>
            </Grid>
            <div className={classes.cardGrid}>
              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewSeniorList}>
                        High School Seniors
                      </section>
                      <aside className={classes.menuDesc}>List of high school seniors.</aside>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} align="left">
                          <summary className={classes.statName}>Onboarded</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>
                            {activeSeniors} / {totalSeniors}
                          </summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>({totalSeniors > 0 && ((activeSeniors / totalSeniors) * 100).toFixed(0)}%)</summary>
                        </Grid>
                      </Grid>
                      {activeSeniors < totalSeniors && (
                        <Grid container direction="row" style={{ marginBottom: 5 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} align="left">
                            <progress
                              id="senior-active-meter"
                              className={classes.progressMeter}
                              min={0}
                              max={totalSeniors}
                              value={activeSeniors}
                            ></progress>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} align="left">
                          <summary className={classes.statName}>Scholarship applicants</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{studentsSubmittedScholarshipApps}</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>
                            ({totalSeniors && ((studentsSubmittedScholarshipApps / totalSeniors) * 100).toFixed(0)}%)
                          </summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 5 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} align="left">
                          <progress
                            id="scholarship-meter"
                            className={classes.progressMeter}
                            min={0}
                            max={totalSeniors}
                            value={studentsSubmittedScholarshipApps}
                          ></progress>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 5 }} />

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} align="left">
                          <summary className={classes.statName}>Trade Life applicants</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{studentsAppliedAftercareTradelife}</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>
                            ({totalSeniors && ((studentsAppliedAftercareTradelife / totalSeniors) * 100).toFixed(0)}%)
                          </summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} align="left">
                          <summary className={classes.statName}>Housing applicants</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{studentsAppliedAftercareHousing}</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>
                            ({totalSeniors && ((studentsAppliedAftercareHousing / totalSeniors) * 100).toFixed(0)}%)
                          </summary>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewAlumniList}>
                        Alumni
                      </section>
                      <aside className={classes.menuDesc}>List of alumni.</aside>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statLink} onClick={handleViewAlumniList}>
                            Active Alumni
                          </summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalAlumni}</summary>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statLink} onClick={handleViewAccessRequests}>
                            Pending access requests
                          </summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{accessRequests}</summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Denied access requests</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{deniedAccessRequests}</summary>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" style={{ marginBottom: 15 }} />

                      {/* <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Scholarship Applications</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{alumniScholarshipApplications}</summary>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Housing Applications</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{alumniHousingApplications}</summary>
                        </Grid>
                      </Grid> */}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewScholarshipApps}>
                        Scholarship Applications
                      </section>
                      <aside className={classes.menuDesc}>List of active scholarship applications.</aside>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Pending applications</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalScholarshipApplications}</summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Under Review applications</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{qualifiedScholarshipApplications}</summary>
                        </Grid>
                      </Grid>
                      {/* <Grid container direction="row" style={{ marginBottom: 0 }}>
                          <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                            <summary className={classes.statName}>Completed applications</summary>
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                            <summary className={classes.statName}>{completedScholarshipApplications}</summary>
                          </Grid>
                        </Grid> */}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewAftercareApps}>
                        Successful Futures Applications
                      </section>
                      <aside className={classes.menuDesc}>List of Successful Futures services applications.</aside>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewTradelifeAftercareApps}
                      >
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statLink}>Trade Life</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{tradelifeApps}</summary>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewTradelifeNewApps}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                        <Grid item xs={8} sm={7} md={7} lg={7} align="left">
                          <summary className={classes.statLink}>New Applications</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{tradelifeSubmittedApps}</summary>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewTradelifeInterviewApps}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                        <Grid item xs={8} sm={7} md={7} lg={7} align="left">
                          <summary className={classes.statLink}>Interviews Scheduled</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{tradelifeInterviewApps}</summary>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewHousingAftercareApps}
                      >
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statLink}>Housing</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{housingApps}</summary>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewHousingNewApps}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                        <Grid item xs={8} sm={7} md={7} lg={7} align="left">
                          <summary className={classes.statLink}>New Applications</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{housingSubmittedApps}</summary>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        style={{ marginBottom: 0 }}
                        className={classes.pointerCursor}
                        onClick={handleViewHousingInterviewApps}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} align="left" />
                        <Grid item xs={8} sm={7} md={7} lg={7} align="left">
                          <summary className={classes.statLink}>Interviews Scheduled</summary>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statValue}>{housingInterviewApps}</summary>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewHelpApps}>
                        Help Requests
                      </section>
                      <aside className={classes.menuDesc}>List of pending Help requests.</aside>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Pending help applications</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalPendingHelpApplications}</summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Closed help applications</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalClosedHelpApplications}</summary>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              <Card className={classes.cardSize}>
                <CardBody>
                  {!auth.roles.includes('STUDENT') && (
                    <React.Fragment>
                      <section className={classes.menuTitle + ' ' + classes.pointerCursor} onClick={handleViewContacts}>
                        Proactive Check-Ins
                      </section>
                      <aside className={classes.menuDesc}>List of proactive check-ins.</aside>

                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Pending Proactive Check-Ins</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalPendingContacts}</summary>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" style={{ marginBottom: 0 }}>
                        <Grid item xs={9} sm={8} md={8} lg={8} align="left">
                          <summary className={classes.statName}>Closed Proactive Check-Ins</summary>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} align="right">
                          <summary className={classes.statName}>{totalClosedContacts}</summary>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </div>
            {staff && (
              <PhoneDialog message={warning} staff={staff} showDialog={showDialog} handleClose={handleDialogClose} handleSave={handleSavePhone} />
            )}
          </React.Fragment>
        )}
        <div className={pageClasses.footer}>
          <div>&copy; {year} Boys Town. All Rights Reserved.</div>
          <div className={pageClasses.version}>Version 1.0.5</div>
        </div>
      </div>
    );
  })
);

export default Index;
