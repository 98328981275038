import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import BTGlobalHeader from '../Header/BTGlobalHeader';
import PublicHeader from '../Header/PublicHeader';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PleaseWait from '../Shared/PleaseWait';
import MenuItems from '../Shared/MenuItems';
import '../../assets/css/nextjs-material-kit-pro.css';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      padding: (props) => (props.isAuthenticated ? '124px 0 5% 0' : '124px 0 20% 0'),
    },
    [theme.breakpoints.down('md')]: {
      padding: (props) => (props.isAuthenticated ? '70px 0 20% 0' : '70px 0 100% 0'),
    },
  },
}));

const Layout = inject('AuthStore')(
  observer((props) => {
    const authStore = props.AuthStore;
    const { accounts } = useMsal();
    const isAuthenticated = accounts.length > 0;
    const classes = useStyles({ isAuthenticated });

    useEffect(() => {
      if (!authStore.notAuthorized && isAuthenticated) {
        authStore.handleUserLogin(accounts[0]);
      }
    }, [accounts, authStore.notAuthorized]);

    return (
      // < style={{ display: 'flex', position: 'relative' }}>
      <div>
        <Hidden mdDown>
          <BTGlobalHeader />
        </Hidden>
        <AuthenticatedTemplate>
          <div className={classes.root}>
            <Header links={<MenuItems dropdownHoverColor="info" authStore={authStore} />} fixed color="primary" />
            <PleaseWait isLoading={authStore.loading} />
            <div className={classes.content}>
              {/* <Button color="secondary" size="small" onClick={(ev) => authStore.setNotAuthorized(false)}>
                Clear Token (Test)
              </Button> */}
              {authStore.notAuthorized && <h2>Sorry, you are not authorized to use this application.</h2>}
              {!authStore.notAuthorized && props.children}
            </div>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className={classes.root}>
            <PublicHeader links={<MenuItems dropdownHoverColor="info" authStore={authStore} />} fixed color="primary" />
            <div>
              <main className={classes.content}>{props.children}</main>
            </div>
            <Footer />
          </div>
        </UnauthenticatedTemplate>
      </div>
    );
  }),
);
export default Layout;
