import { makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode';
import { callApi, logoutGoogle } from '../util/apiWrapper';
import { msalConfig } from '../services/msal';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../services/appInsights';
import { DateTime } from 'luxon';

class AuthStore {
  authToken = '';
  notAuthorized = false;
  user = null;
  loading = false;
  authExpires = DateTime.now().plus({ hours: 10 });
  msalInstance;
  maslAccount;
  roles = [];

  constructor(msalInstance) {
    this.msalInstance = msalInstance;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get getUser() {
    return this.user;
  }

  get getToken() {
    return this.authToken;
  }

  get getAuthExpires() {
    return this.authExpires;
  }

  setUser = (user) => {
    this.user = user;
  };

  setAuthToken = (token) => {
    this.authToken = token;
  };

  setAuthExpires = () => {
    this.authExpires = DateTime.now().plus({ hours: 10 });
  };

  setLoading = (val) => {
    this.loading = val;
  };

  setNotAuthorized(isAuthorized) {
    this.notAuthorized = isAuthorized;
    this.user = null;
    this.authToken = '';
  }

  setRoles = (roles) => {
    this.roles = roles;
  };

  logoutUser() {
    this.notAuthorized = false;
    this.user = null;
    this.authToken = '';
  }

  // NOTE: There is never a guarantee that a token can be acquired silently even if the refresh token has not expired yet. The
  // patterns described above are best effort attempts to minimize interaction at inconvenient times but will not eliminate
  // the possibility of required interactions within the desired timeframes.

  async handleUserLogin(account) {
    try {
      this.setLoading(true);
      const tokenResponse = await this.msalInstance.acquireTokenSilent({
        scopes: msalConfig.api.scopes,
        account: account,
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 36000,
      });
      this.setAuthToken(tokenResponse.accessToken);
      this.setAuthExpires();
      appInsights.trackEvent({ name: 'login', properties: { email: account.idTokenClaims.emails[0] } });
      const userResponse = await callApi(this, `user?email=${account.idTokenClaims.emails[0]}&is_active=1&is_login=1`, 'GET', null);
      this.setUser(userResponse);
      this.setRoles(userResponse.result.roles);
      this.notAuthorized = false;
      this.maslAccount = account;
      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);
      this.setNotAuthorized(true);
      if (JSON.stringify(error).indexOf('InteractionRequiredAuthError') > -1) {
        await logoutGoogle();
        this.msalInstance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
      } else {
        appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
        console.log('error login', error);
      }
    }
  }

  async checkToken() {
    try {
      if (!this.user) {
        // this.msalInstance.loginRedirect(msalConfig.loginRequest);
        return;
      }
      if (!this.maslAccount) {
        // this.msalInstance.loginRedirect(msalConfig.loginRequest);
        return;
      }
      if (this.authExpires > DateTime.now()) {
        if (this.isTokenCloseToExpire(this.user.accessToken)) {
          this.setLoading(true);
          const tokenResponse = await this.msalInstance.acquireTokenSilent({
            scopes: msalConfig.api.scopes,
            account: this.maslAccount,
          });
          this.user.accessToken = tokenResponse.accessToken;
          this.setAuthToken(tokenResponse.accessToken);
          this.setLoading(false);
        }
        return this.accessToken;
      } else {
        await this.msalInstance.logoutRedirect();
      }
    } catch (error) {
      this.setLoading(false);
      console.log('error login', error);
      throw error;
    }
  }

  isTokenCloseToExpire = (token) => {
    try {
      const decoded = jwt_decode(token);
      const expDate = new Date(decoded.exp * 1000);
      const now = new Date();
      expDate.setMinutes(expDate.getMinutes() - 10);
      if (expDate.getTime() < now.getTime()) {
        return true;
      } else return false;
    } catch (err) {
      console.log(err);
      return true;
    }
  };

  isTokenExpired = () => {
    try {
      const decoded = jwt_decode(this.authToken);
      const expDate = new Date(decoded.exp * 1000);
      const now = new Date();
      if (expDate.getTime() < now.getTime()) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return true;
    }
  };
}

export default AuthStore;
