import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField } from '@material-ui/core';
import CollegeShared from './CollegeShared';
import { convertFile, arrayBufferToBase64 } from '../../../util/stringUtil';

const TwoYearCollege = ({ form, setForm, classes, disabled }) => {
  const [uploadError, setUploadError] = useState();
  const [studentAidReport, setStudentAidReport] = useState(null);

  useEffect(() => {
    if (form.studentAidReport) {
      let tempFile = JSON.parse(JSON.stringify(form.studentAidReport));
      tempFile.browserFile = convertFile({ file: tempFile.data, file_type: tempFile.file_type, file_name: tempFile.filename });
      setStudentAidReport(tempFile);
    }
  }, [form.studentAidReport]);

  const downloadFile = async (file) => {
    let a = document.createElement('a'); //Create <a>
    a.href = `data:${file.browserFile.type};base64,${arrayBufferToBase64(await file.browserFile.arrayBuffer())}`;
    a.download = file.browserFile.name;
    a.click();
  };

  const removeFile = () => {
    setStudentAidReport(null);
    setForm({ ...form, studentAidReport: null });
  };

  const checkExtensionForDocument = (file) => {
    return /\.(doc|docx|pdf|txt)$/i.test(file[0].name);
  };

  const confirmFileUpload = async (file) => {
    try {
      if (checkExtensionForDocument(file)) {
        const data = arrayBufferToBase64(await file[0].arrayBuffer());
        const filedetails = {
          'data': data,
          'file_type': file[0].type,
          'filename': file[0].name,
          'browserFile': convertFile({ file: data, file_type: file[0].type, file_name: file[0].name }),
        };
        setStudentAidReport(filedetails);
        let tempFile = JSON.parse(JSON.stringify(filedetails));
        delete tempFile.browserFile;
        setForm({ ...form, studentAidReport: tempFile });
        setUploadError('');
      } else {
        setUploadError('Error: The file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded.');
      }
    } catch (error) {
      console.log('Error setting essay details:', error);
      setUploadError('Sorry, an error occurred. Please try again.');
    }
  };

  return (
    <>
      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>ACCUPLACER</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl>
            <TextField
              id="accuplacer"
              size="small"
              hiddenLabel
              placeholder="ACCUPLACER Score"
              variant="outlined"
              value={form.accuplacerScore}
              onChange={(event) => setForm({ ...form, accuplacerScore: event.target.value })}
              InputProps={{ maxLength: 50, classes: { input: classes.textField } }}
              disabled={disabled}
            />
          </FormControl>
          <FormControlLabel
            style={{ marginLeft: '10px' }}
            control={
              <Checkbox
                color="default"
                disabled={disabled}
                checked={form.accuplacerNotTaken ? form.accuplacerNotTaken : false}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    setForm({ ...form, accuplacerNotTaken: true, accuplacerScore: '' });
                  } else {
                    setForm({ ...form, accuplacerNotTaken: ev.target.checked });
                  }
                }}
              />
            }
            label="Have Not Taken"
          />
        </Grid>
      </Grid>
      {form.accuplacerNotTaken && (
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
            <label>Explain why ACCUPLACER not taken</label>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rowSpacer}>
            <FormControl fullWidth>
              <TextField
                id="accuplacer-not-taken"
                size="small"
                hiddenLabel
                placeholder="Please provide more information"
                variant="outlined"
                value={form.accuplacerNotTakenReason}
                onChange={(event) => setForm({ ...form, accuplacerNotTakenReason: event.target.value })}
                InputProps={{ maxLength: 255, classes: { input: classes.textField } }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={12} sm={2} className={classes.textRowLabelSpacer}>
          <label>ACCUPLACER</label>
        </Grid>
        <Grid item xs={1} md={false} lg={false} className={classes.rowSpacer} />
        <Grid item xs={11} sm={5} className={classes.rowSpacer}>
          {form.accuplacerScore}
        </Grid>
        {form.accuplacerNotTaken && (
          <Grid item xs={11} sm={5} className={classes.rowSpacer}>
            <span>ACCUPLACER not taken: </span>
            {form.accuplacerNotTakenReason}
          </Grid>
        )}
      </Grid>

      <Grid container direction="row" className={classes.unprintedContent}>
        <Grid item xs={12} md={3} className={classes.textRowLabelSpacer}>
          <label>Student Aid Index?</label>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rowSpacer}>
          <FormControl fullWidth>
            <RadioGroup
              row
              aria-label="Student Aid Index?"
              name="student-aid-report"
              defaultValue={form.studentAidReportCompleted}
              value={form.studentAidReportCompleted}
              onChange={(event) => setForm({ ...form, studentAidReportCompleted: event.target.value })}
              disabled={disabled}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>Yes</span>}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" disabled={disabled} />}
                label={<span className={classes.radioLabel}>No</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.printedContent}>
        <Grid item xs={6} sm={2} className={classes.textRowLabelSpacer}>
          <label>Student Aid Index?</label>
        </Grid>
        <Grid item xs={6} sm={4} className={classes.rowSpacer}>
          {form.studentAidReportCompleted}
        </Grid>
      </Grid>

      {form.studentAidReportCompleted === 'yes' && (
        <Grid container direction="row">
          <Grid item xs={12} className={classes.unprintedContent}>
            <Paper style={{ marginBottom: '20px' }}>
              <div style={{ borderTop: '3px dotted teal', padding: 20 }}>
                {studentAidReport && (
                  <div style={{ marginBottom: 10 }}>
                    <Chip
                      style={{ margin: 5 }}
                      label={studentAidReport && studentAidReport.filename}
                      onDelete={disabled ? null : removeFile}
                      color="primary"
                      onClick={() => downloadFile(studentAidReport)}
                    />
                  </div>
                )}
                {!studentAidReport && (
                  <div>
                    <Button variant="outlined" component="label" color="primary">
                      Upload Student Aid Index
                      <input type="file" hidden onChange={(e) => confirmFileUpload(e.target.files)} />
                    </Button>
                    {studentAidReport && studentAidReport.name}
                    <br />
                    <aside className={classes.error}>{uploadError}</aside>
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
      {form.studentAidReportCompleted === 'yes' && studentAidReport && (
        <Grid container direction="row">
          <Grid item xs={12} className={classes.printedContent}>
            {studentAidReport.filename} has been uploaded.
          </Grid>
        </Grid>
      )}

      <CollegeShared form={form} setForm={setForm} classes={classes} disabled={disabled} />
    </>
  );
};

TwoYearCollege.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TwoYearCollege;
