import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormLabel,
  Button,
  Grid,
  Hidden,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  TablePagination,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableStyle from '../../styles/TableStyle';
import { observer, inject } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { btNavyBlueColor, btMediumGrayColor } from '../../assets/jss/nextjs-material-kit-pro';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import { titleize } from '../../util/stringUtil';
import { dateUtcToS, dateTimeToS } from '../../util/dateUtil';
import TitleBar from '../Shared/TitleBar';
import AftercareApplicationCards from './AftercareApplicationCards';
import PageUnauthorized from '../Shared/PageUnauthorized';

const tableStyles = makeStyles(TableStyle);

const useStyles = makeStyles((theme) => ({
  noLeftPadding: {
    paddingLeft: '0 !important',
  },
  sortHeader: {
    cursor: 'pointer',
    color: btNavyBlueColor,
  },
  nosortHeader: {
    color: btMediumGrayColor,
  },
  approvalLink: {
    fontSize: '0.85rem',
    padding: '2px, 5px',
    textTransform: 'none',
    justifyContent: 'center',
  },
  idNoLink: {
    padding: 0,
  },
  gridLink: {
    color: btNavyBlueColor,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    textTransform: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerAlignContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  filter: {
    margin: '5px 0 15px 20px',
  },
  switchLeadingText: {
    margin: '5px 20px 15px 0',
  },
  noSeniorsMsg: {
    fontSize: '1.15rem',
    margin: 5,
  },
  whiteBg: {
    background: '#FFFFFF',
  },
  centerRadioSorting: {
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'space-between',
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  selectDropdown: {
    color: '#333',
    backgroundColor: '#fff',
  },
  menuItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: '#8c857b',
    },
  },
}));

const AftercareApplicationsList = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const history = useHistory();

    const classes = useStyles();
    const tableClasses = tableStyles();

    const [pageState, setPageState] = useState({
      allRecords: [],
      records: [],
      filter: '',
      fetchedRecords: false,
      loading: false,
      showAllRecords: false,
      sortDirection: 'asc',
      sortBy: '',
      serviceTypeFilter: store.aftercareServiceTypeFilter,
      statusFilter: store.aftercareStatusFilter,
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [pageOptions, setPageOptions] = useState({
      searching: false,
      page: 0,
      perPage: 10,
    });

    useEffect(() => {
      const fetchRecords = async () => {
        try {
          setPageState({ ...pageState, loading: true });
          const rsp = await callApi(auth, `aftercare/disposition?siteList=${store.staffsites}`, 'GET');

          for (let i = 0; i < rsp.result.length; i++) {
            const r = rsp.result[i];
            r.submitted_date_formatted = dateTimeToS(r.submitted_date);
            r.interview_at_formatted = dateUtcToS(r.interview_at);
            r.service_type === 'tradelife' ? (r.service_type_formatted = 'Trade Life') : (r.service_type_formatted = 'Housing');
          }
          if (store.aftercareServiceTypeFilter && store.aftercareServiceTypeFilter.length > 0) {
            rsp.result = rsp.result.filter((r) => {
              return r.service_type && r.service_type.toLowerCase().includes(store.aftercareServiceTypeFilter.toLowerCase());
            });
          }
          if (store.aftercareStatusFilter && store.aftercareStatusFilter.length > 0) {
            if (store.aftercareStatusFilter === 'new') {
              rsp.result = rsp.result.filter((r) => {
                return (r.status && r.status.toLowerCase().includes('submitted')) || (r.status && r.status.toLowerCase().includes('qualified'));
              });
            } else if (store.aftercareStatusFilter === 'completed') {
              rsp.result = rsp.result.filter((r) => {
                return (r.status && r.status.toLowerCase().includes('approved')) || (r.status && r.status.toLowerCase().includes('denied'));
              });
            } else {
              rsp.result = rsp.result.filter((r) => {
                return r.status && r.status.toLowerCase().includes(store.aftercareStatusFilter.toLowerCase());
              });
            }
          } else {
            // Show all aftercare apps needing to be handled (exclude created/completed).
            rsp.result = rsp.result.filter((r) => {
              return (
                (r.status && r.status.toLowerCase().includes('submitted')) ||
                (r.status && r.status.toLowerCase().includes('qualified')) ||
                (r.status && r.status.toLowerCase().includes('qualifiedinitial')) ||
                (r.status && r.status.toLowerCase().includes('qualifiedprogram')) ||
                (r.status && r.status.toLowerCase().includes('interview'))
              );
            });
          }
          setPageState({
            ...pageState,
            loading: false,
            records: [...rsp.result],
            allRecords: [...rsp.result],
            sortBy: 'submitted_date',
            fetchedRecords: true,
          });
        } catch (error) {
          logException(error);
          setPageState({ ...pageState, loading: false, fetchedRecords: true });
          setErrorMsg('An unexpected error has occurred. Please try again.');
          console.log('Error fetching list of submitted aftercare applications:', error);
        }
      };
      if (auth.authToken && !pageState.fetchedRecords && !pageState.loading) {
        fetchRecords();
      }
    }, [
      auth,
      auth.authToken,
      pageState,
      pageState.fetchedRecords,
      pageState.allRecords,
      store.aftercareStatusFilter,
      store.aftercareServiceTypeFilter,
    ]);

    useEffect(() => {
      if (auth.roles && auth.roles.length > 0) {
        if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
          history.push('/seniorplanner');
        }
      }
    }, [auth.roles, history]);

    useEffect(() => {
      const compareValues = (key, order = 'asc') => {
        return function innerSort(a, b) {
          const varA = !a.hasOwnProperty(key) ? '' : a[key];
          const varB = !b.hasOwnProperty(key) ? '' : b[key];
          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      let sortedRecords = pageState.allRecords;
      if (sortedRecords) {
        if (!pageState.showAllRecords) {
          sortedRecords = sortedRecords.filter((x) => !x.approved);
        }
        sortedRecords.sort(compareValues(pageState.sortBy, pageState.sortDirection));
        setPageState((p) => {
          return { ...p, records: sortedRecords };
        });
      }
    }, [pageState.showAllRecords, pageState.sortBy, pageState.sortDirection, pageState.allRecords]);

    useEffect(() => {
      let filteredData = pageState.allRecords || [];
      if (pageState.filter && pageState.filter.length > 0) {
        filteredData = filteredData.filter((r) => {
          return (
            (r.submitted_by && r.submitted_by.toLowerCase().includes(pageState.filter.toLowerCase())) ||
            (r.status && r.status.toLowerCase().includes(pageState.filter.toLowerCase())) ||
            (r.submitted_date_formatted && r.submitted_date_formatted.toString().includes(pageState.filter)) ||
            (r.interview_at_formatted && r.interview_at_formatted.toString().includes(pageState.filter)) ||
            (r.service_type_formatted && r.service_type_formatted.toLowerCase().includes(pageState.filter.toLowerCase()))
          );
        });
      }
      setPageState((p) => {
        return { ...p, records: filteredData };
      });
    }, [pageState.filter, pageState.allRecords]);

    const setSort = (val) => {
      let direction = 'asc';
      if (val === pageState.sortBy) {
        if (pageState.sortDirection === 'asc') {
          direction = 'desc';
        } else {
          direction = 'asc';
        }
        setPageState({ ...pageState, sortDirection: direction });
      } else {
        setPageState({ ...pageState, sortDirection: 'asc', sortBy: val });
      }
    };

    const handleOpenAftercareApp = async (event, id) => {
      event.preventDefault();
      event.stopPropagation();
      store.setAftercareAppId(id);
      try {
        store.setLoading(true);
        store.setSearching(true);
        let application = await callApi(auth, 'aftercare/' + id, 'GET');
        store.setAftercareApp(application.result);
        store.setAftercareAppId(application.result.id);
        const appUserId = application.result.userId;
        let senior = await callApi(auth, 'studentplan/' + appUserId, 'GET');
        store.setSenior(senior.result);
        store.setSearching(false);
        store.setLoading(false);
        history.push('/aftercareapp');
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error fetching senior:', error);
      }
    };

    const displayStatus = (status) => {
      if (status === 'submitted') {
        return 'Submitted';
      }
      if (status === 'qualified' || status === 'qualifiedinitial') {
        return 'Qualified - Initial';
      }
      if (status === 'qualifiedprogram') {
        return 'Qualified - Program';
      }
      if (status === 'awarded') {
        return 'Awarded';
      }
      if (status === 'denied') {
        return 'Denied';
      }
      return status;
    };

    const handleCardSortChange = (event) => {
      setPageState({ ...pageState, sortBy: event.target.value });
    };

    const filterData = (val) => {
      setPageState({ ...pageState, filter: val });
    };

    const emptyRows =
      pageOptions.perPage - Math.min(pageOptions.perPage, pageState.records && pageState.records.length - pageOptions.page * pageOptions.perPage);

    if (auth.roles && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI'))) {
      return <PageUnauthorized />;
    }

    return (
      <Grid container className={classes.whiteBg}>
        <React.Fragment>
          <PleaseWait isLoading={pageState.loading} />
          <TitleBar
            title="Successful Futures Services Applications"
            currentPage="Successful Futures Services Applications"
            paths={[{ path: '/', name: 'Home' }]}
          />
          <Grid container className={classes.whiteBg}>
            <Grid item xs={12}>
              <FormLabel error>{errorMsg}</FormLabel>
            </Grid>

            <Grid container className={classes.whiteBg} align="left">
              <Grid item xs={6} className={classes.whiteBg} align="left">
                <TextField
                  id="filter-field"
                  type="search"
                  className={classes.filter}
                  size="small"
                  hiddenLabel
                  placeholder="Filter"
                  variant="outlined"
                  onChange={(event) => filterData(event.target.value)}
                  clearable="true"
                />
              </Grid>
              <Grid item xs={6} align="right" className={classes.whiteBg}></Grid>

              <Grid item xs={12}>
                <Hidden mdDown implementation="css">
                  <TableContainer component={Paper}>
                    <Table className={tableClasses.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.noSortHeader} style={{ width: '100px', textAlign: 'center' }}>
                            REVIEW
                          </TableCell>
                          <Tooltip title="Click to sort by name." placement="top">
                            <TableCell onClick={() => setSort('submitted_by')} className={classes.sortHeader} style={{ width: '200px' }}>
                              SUBMITTED BY{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by service type." placement="top">
                            <TableCell onClick={() => setSort('service_type_formatted')} className={classes.sortHeader} style={{ width: '130px' }}>
                              SERVICE TYPE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'service_type_formatted'
                                      ? pageState.sortDirection === 'asc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'service_type_formatted'
                                      ? pageState.sortDirection === 'desc'
                                        ? 'inline-block'
                                        : 'none'
                                      : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by status." placement="top">
                            <TableCell onClick={() => setSort('status')} className={classes.sortHeader} style={{ width: '120px' }}>
                              STATUS{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display: pageState.sortBy === 'status' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display: pageState.sortBy === 'status' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by status date." placement="top">
                            <TableCell onClick={() => setSort('submitted_date')} className={classes.sortHeader} style={{ width: '170px' }}>
                              SUBMITTED DATE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          <Tooltip title="Click to sort by interview date." placement="top">
                            <TableCell onClick={() => setSort('interview_at')} className={classes.sortHeader} style={{ width: '140px' }}>
                              INTERVIEW DATE{' '}
                              <span
                                className="fas fa-caret-up"
                                style={{
                                  display:
                                    pageState.sortBy === 'interview_at' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                              <span
                                className="fas fa-caret-down"
                                style={{
                                  display:
                                    pageState.sortBy === 'interview_at' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageOptions.searching ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography variant="h5">Searching...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          pageState.records &&
                          pageState.records
                            .slice(pageOptions.page * pageOptions.perPage, pageOptions.page * pageOptions.perPage + pageOptions.perPage)
                            .map((record, idx) => (
                              <TableRow key={record.id}>
                                <TableCell style={{ width: '100px', textAlign: 'center' }}>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.approvalLink}
                                    aria-describedby="approve request"
                                    onClick={(ev) => handleOpenAftercareApp(ev, record.id)}
                                  >
                                    Review
                                  </Button>
                                </TableCell>
                                <TableCell style={{ width: '200px' }}>{record.submitted_by}</TableCell>
                                <TableCell style={{ width: '130px' }}>
                                  {record.service_type_formatted && titleize(record.service_type_formatted)}
                                </TableCell>
                                <TableCell style={{ width: '120px' }}>{titleize(displayStatus(record.status))}</TableCell>
                                <TableCell style={{ width: '170px' }}>{record.submitted_date_formatted}</TableCell>
                                <TableCell style={{ width: '140px' }}>
                                  {record.interview_at_formatted ? dateUtcToS(record.interview_at_formatted) : ''}
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                        {!pageOptions.searching && pageState.records && pageState.records.length === 0 ? (
                          <TableRow>
                            <TableCell align="center" colSpan={8} className={classes.noLeftPadding}>
                              <summary className={classes.noSeniorsMsg}>No records found.</summary>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 34 * emptyRows }}>
                            <TableCell colSpan={8} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      colSpan={8}
                      SelectProps={{
                        InputProps: { 'aria-label': 'rows per page' },
                        MenuProps: { classes: { paper: classes.selectDropdown } },
                      }}
                      classes={{ menuItem: classes.menuItem }}
                      rowsPerPageOptions={[10, 15, 25, 50, 100]}
                      count={pageState.records ? pageState.records.length : 0}
                      rowsPerPage={pageOptions.perPage}
                      page={pageOptions.page}
                      onChangePage={(ev, val) => {
                        setPageOptions({ ...pageOptions, page: val });
                      }}
                      onChangeRowsPerPage={(ev) => setPageOptions({ ...pageOptions, perPage: parseInt(ev.target.value, 10), page: 0 })}
                    />
                  </TableContainer>
                </Hidden>

                <Hidden lgUp implementation="css">
                  <Grid container>
                    <Grid item xs={12} sm={10} md={7} className={classes.radioRow}>
                      <RadioGroup
                        aria-label="card-sorting"
                        name="date-group"
                        className={classes.centerRadioSorting}
                        row
                        value={pageState.sortBy}
                        onChange={handleCardSortChange}
                      >
                        <FormControlLabel
                          value="submitted_by"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Name</span>}
                          onClick={() => setSort('submitted_by')}
                          className={classes.radioLabel}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'submitted_by' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          value="service_type"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Type</span>}
                          onClick={() => setSort('service_type')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'service_type' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display: pageState.sortBy === 'service_type' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                        <FormControlLabel
                          value="submitted_date"
                          control={<Radio color="default" />}
                          label={<span className={classes.radioLabel}>Sort by Date</span>}
                          onClick={() => setSort('submitted_date')}
                          className={classes.radioLabel}
                          style={{
                            marginLeft: '20px',
                          }}
                        />
                        <aside
                          style={{
                            marginLeft: '-15px',
                            marginTop: '8px',
                            marginRight: '10px',
                          }}
                        >
                          <span
                            className="fas fa-caret-up"
                            style={{
                              display: pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'asc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                          <span
                            className="fas fa-caret-down"
                            style={{
                              display:
                                pageState.sortBy === 'submitted_date' ? (pageState.sortDirection === 'desc' ? 'inline-block' : 'none') : 'none',
                            }}
                          />
                        </aside>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <AftercareApplicationCards list={pageState.records} handleOpenAftercareApp={handleOpenAftercareApp} />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  })
);

export default AftercareApplicationsList;
