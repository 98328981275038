import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { dateUtcToS, displayDateAsUTC, getTodaysDate, dateOnlyFormat } from '../../util/dateUtil';
import GeneralInformation from './AftercareAppComponents/GeneralInformation';
import ServiceType from './AftercareAppComponents/ServiceType';
import CareerInterests from './AftercareAppComponents/CareerInterests';
import Essay from './AftercareAppComponents/Essay';
import InformedConsent from './AftercareAppComponents/InformedConsent';
import { callApi } from '../../util/apiWrapper';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { convertFile, titleize } from '../../util/stringUtil';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import { logException, appInsights } from '../../services/appInsights';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  subTitle: {
    margin: '15px 0',
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  firstSectionSpacer: {
    height: 40,
  },
  sectionSpacer: {
    height: 60,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.only('xs')]: {
      height: 125,
    },
    [theme.breakpoints.only('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('md')]: {
      height: 75,
    },
  },
  textArea10RowSpacer: {
    height: 185,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
      overflow: 'auto',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  buttonWidth: {
    width: 110,
  },
  floatingButton: {
    width: 110,
    position: 'absolute',
    right: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  firstSectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  sectionSubTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.80rem',
      fontWeight: 600,
      margin: '2px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.90rem',
      fontWeight: 600,
      margin: '5px 0',
    },
  },
  hideForXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideForMd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  bigLabel: {
    fontSize: '0.85rem',
  },
  addButton: {
    padding: 0,
    color: btNavyBlueColor,
  },
  topMargin10: {
    marginTop: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  marginRight40: {
    marginRight: 40,
  },
  statusOption: {
    width: '140px',
  },
  warning: {
    fontSize: '0.95rem',
    color: 'blue',
    fontWeight: 500,
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  dispositionNote: {
    fontSize: '1.05rem',
    padding: 15,
    '@media print': {},
    '@media screen': {
      border: '1px solid blue',
      backgroundColor: '#FFFAF0',
    },
  },
  reviewSection: {
    [theme.breakpoints.down('md')]: {
      height: '600px',
    },
    [theme.breakpoints.up('md')]: {
      height: '300px',
    },
  },
  reviewStatus: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '110px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginBottom: 0,
    },
  },
  reason: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  reviewDates: {
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
      height: '180px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
}));

const AftercareApp = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const { handleSaveReturn } = props;

    const formClasses = formStyles();

    const [originalStatus, setOriginalStatus] = useState('');
    const [status, setStatus] = useState('created');
    const [form, setForm] = useState({
      currentStudent: '',
      addressEntered: '',
      cityStateZip: '',
      motivationSystem: '',
      careerInterests: '',
      noWorkHistory: false,
      workHistory: [
        {
          employer: '',
          startDate: '',
          endDate: '',
        },
        {
          employer: '',
          startDate: '',
          endDate: '',
        },
        {
          employer: '',
          startDate: '',
          endDate: '',
        },
      ],
      plansCommunityCollege: false,
      plansMilitary: false,
      plans4YearCollege: false,
      plansWorkforce: false,
      plansVocationalTraining: false,
      plansOther: false,
      plansJobCorps: false,
      plansOtherExplain: '',
      boystownEmployment: '',
      boystownAftercare: false,
      boystownIT: false,
      boystownFacilities: false,
      boystownLegal: false,
      boystownFinance: false,
      boystownMarketing: false,
      boystownHospital: false,
      boystownSecurity: false,
      boystownHR: false,
      boystownYouthCare: false,
      boystownInfoSec: false,
      acSummerLivingArrangements: '',
      acFallLivingArrangements: '',
      otherIncome: '',
      explainOtherIncome: '',
      guardiansFinancialHelp: '',
      acHealthInsurance: '',
      essay: '',
      housingServicesSignature: '',
      housingServicesDate: '',
      tradeLifeSignature: '',
      tradeLifeDate: '',
    });

    const [serviceType, setServiceType] = useState('');
    const [joinBoth, setJoinBoth] = useState('');
    const [essayFile, setEssayFile] = useState(null);
    const [dispositionReason, setDispositionReason] = useState('');
    const [interviewAtSet, setInterviewAtSet] = useState(false);
    const [interviewAt, setInterviewAt] = useState(null);
    const [originalInterviewAt, setOriginalInterviewAt] = useState(null);
    const [dispositionLetterSent, setDispositionLetterSent] = useState(null);
    const [submittedBy, setSubmittedBy] = useState(null);
    const [requiredFields, setRequiredFields] = useState(null);
    const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
    const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);
    const [savedFormJson, setSavedFormJson] = useState(null);
    const [savedEssay, setSavedEssay] = useState(null);

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    useEffect(() => {
      const autoSaveAftercareApp = async () => {
        if (savedFormJson !== JSON.stringify(form) || savedEssay !== essayFile) {
          try {
            let file = null;
            if (essayFile) {
              file = essayFile;
              if (essayFile.id) {
                file.id = essayFile.id;
              }
            }

            const item = {
              id: store.aftercareAppId,
              userId: store.senior.userId,
              application: form,
              service_type: serviceType,
              essay: file,
              status: status,
              submitted_date: store.aftercareApp.submitted_date,
              status_date: new Date(),
              status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
              disposition_reason: dispositionReason,
              interview_at: interviewAt,
              disposition_letter_sent: dispositionLetterSent,
              submitted_by: submittedBy,
              apply_both_programs: joinBoth === 'yes' ? true : joinBoth === 'no' ? false : null,
            };

            await callApi(auth, 'aftercare', 'PUT', {
              item,
            });

            setSavedFormJson(JSON.stringify(form));
            setSavedEssay(file);
            appInsights.trackTrace({ message: `Auto saved Successful Futures form for ${auth.user.result.email}` });
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.log('Error auto saving Successful Futures application:', error);
          }
        }
      };

      if (auth.roles && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI'))) {
        const interval = setInterval(() => {
          autoSaveAftercareApp();
        }, 300000);
        return () => clearInterval(interval);
      }
    }, [
      auth.roles,
      auth,
      serviceType,
      submittedBy,
      dispositionReason,
      dispositionLetterSent,
      essayFile,
      savedEssay,
      interviewAt,
      joinBoth,
      form,
      savedFormJson,
      status,
      store,
    ]);

    useEffect(() => {
      if (store.aftercareApp) {
        setServiceType(store.aftercareApp.service_type);
        setJoinBoth(store.aftercareApp.apply_both_programs === undefined ? '' : store.aftercareApp.apply_both_programs ? 'yes' : 'no');
        setStatus(store.aftercareApp.status);
        setOriginalStatus(store.aftercareApp.status);
        setInterviewAt(store.aftercareApp.interview_at);
        setOriginalInterviewAt(store.aftercareApp.interview_at);
        setSubmittedBy(store.aftercareApp.submitted_by);
        setDispositionReason(store.aftercareApp.disposition_reason);
        setDispositionLetterSent(store.aftercareApp.disposition_letter_sent);
        if (store.aftercareApp.application && store.aftercareApp.application !== null) {
          setForm(JSON.parse(store.aftercareApp.application));
        }
        if (store.aftercareApp.essay && store.aftercareApp.essay !== null) {
          let stateEssayFile = JSON.parse(JSON.stringify(store.aftercareApp.essay));
          stateEssayFile.browserFile = convertFile({
            file: stateEssayFile.essay,
            file_type: stateEssayFile.file_type,
            file_name: stateEssayFile.filename,
          });
          setEssayFile(stateEssayFile);
        }
      }
    }, [store.aftercareApp]);

    const handleWorkHistoryRow = () => {
      setForm({
        ...form,
        workHistory: [
          ...form.workHistory,
          {
            employer: '',
            startDate: '',
            endDate: '',
          },
        ],
      });
    };

    const sendApplicationSubmittedEmail = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'aftercaresubmitemail', 'POST', {
          student_id: id,
          service_type: serviceType === 'tradelife' ? 'Trade Life' : 'Housing',
        });
        appInsights.trackTrace({ message: `Successful Futures form submitted email sent to ${auth.user.result.email}` });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending Successful Futures submitted email:', error);
      }
    };

    const sendTradeLifeSubmittedEmail = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'tradelifesubmittedemail', 'POST', {});
        appInsights.trackTrace({ message: 'Trade Life submitted email sent to staff_trade_life_submitted config.' });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending trade life submitted email:', error);
      }
    };

    const sendHousingSubmittedEmail = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'housingsubmittedemail', 'POST', {});
        appInsights.trackTrace({ message: 'Housing submitted email sent to staff_housing_submitted config.' });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending housing submitted email:', error);
      }
    };

    const sendApplicationReturnedEmail = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'aftercarereturnemail', 'POST', { student_id: id });
        appInsights.trackTrace({ message: `Successful Futures form returned email sent to ${auth.user.result.email}` });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending Successful Futures returned email:', error);
      }
    };

    const handleSaveApplication = () => {
      if ((auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && canDisposition() === false) {
        setShowRequiredFieldsDialog(true);
        return;
      }

      async function writeAftercareApplication() {
        try {
          store.setLoading(false);
          store.setSearching(true);

          let file = null;
          if (essayFile) {
            file = essayFile;
            if (essayFile.id) {
              file.id = essayFile.id;
            }
          }

          // Send email
          if (originalStatus !== 'returned' && status === 'returned') {
            sendApplicationReturnedEmail(store.senior.id);
          }

          const item = {
            id: store.aftercareAppId,
            userId: store.senior.userId,
            application: form,
            service_type: serviceType,
            essay: file,
            status: status,
            submitted_date: store.aftercareApp.submitted_date,
            status_date: new Date(),
            status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
            disposition_reason: dispositionReason,
            interview_at: interviewAt,
            disposition_letter_sent: dispositionLetterSent,
            submitted_by: submittedBy,
            apply_both_programs: joinBoth === 'yes' ? true : joinBoth === 'no' ? false : null,
          };

          const app = await callApi(auth, 'aftercare', 'PUT', {
            item,
          });

          setSavedFormJson(JSON.stringify(form));
          setSavedEssay(file);

          appInsights.trackTrace({ message: `Successful Futures form saved by ${auth.user.result.email}` });

          if (app.result) {
            store.setAftercareApp(app.result);
            store.setAftercareAppId(app.result.id);
            setEssayFile(app.result.essay);
          }

          store.setSearching(false);
          store.setLoading(false);
          handleSaveReturn();
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error writing updated Successful Futures application:', error);
        }
      }
      writeAftercareApplication();
    };

    const handleSubmitApplication = () => {
      if (canSubmit()) {
        async function submitAftercareApplication() {
          try {
            store.setLoading(false);
            store.setSearching(true);

            let file = null;
            if (essayFile) {
              file = essayFile;
              if (essayFile.id) {
                file.id = essayFile.id;
              }
            }

            sendApplicationSubmittedEmail(store.senior.id);

            if (serviceType === 'housing') {
              sendHousingSubmittedEmail();
            }
            if (serviceType === 'tradelife') {
              sendTradeLifeSubmittedEmail();
            }

            const app = await callApi(auth, 'aftercare', 'PUT', {
              item: {
                id: store.aftercareAppId,
                userId: store.senior.userId,
                application: form,
                service_type: serviceType,
                essay: file,
                submitted_date: new Date(),
                status: 'submitted',
                status_date: new Date(),
                status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
                disposition_reason: '',
                interview_at: interviewAt || null,
                disposition_letter_sent: dispositionLetterSent || null,
                submitted_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
                apply_both_programs: joinBoth === 'yes' ? true : joinBoth === 'no' ? false : null,
              },
            });

            if (app.result) {
              store.setAftercareApp(app.result);
              setEssayFile(app.result.essay);
            }

            // Update aftercareList with new status of 'submit' for this app, so any warnings disappear.
            // let allAftercareApps = store.aftercareList;
            // allAftercareApps.find((v) => v.id === store.aftercareAppId).status = 'submitted';
            // store.setAftercareList(allAftercareApps);

            appInsights.trackTrace({ message: `mitted by ${auth.user.result.email}` });

            store.setSearching(false);
            store.setLoading(false);
            handleSaveReturn();
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.log('Error submitting updated Successful Futures application:', error);
          }
        }
        submitAftercareApplication();
      } else {
        setShowRequiredFieldsDialog(true);
      }
    };

    const handleInterviewAtChange = (date) => {
      setInterviewAt(date);
      setInterviewAtSet(true);
      setStatus('interview');
    };

    const handleUnderstand = () => {
      store.setAppCreated(false);
    };

    const isEditDisabled = () => {
      let rc = true;
      if (auth.roles && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI'))) {
        // Status Values: submitted, qualified (initial), qualifiedprogram, interview, approved, denied, ApprovedAlternate, returned
        if (status === 'created' || status === 'returned') {
          rc = false;
        }
      }
      return rc;
    };

    const handleRequiredInfoClose = () => {
      setShowRequiredFieldsDialog(false);
    };

    const displayStatus = (status) => {
      if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
        return 'Under Review';
      }
      if (status === 'approved') {
        return 'Processed';
      }
      if (status === 'awarded') {
        return 'Processed';
      }
      if ((status = 'ApprovedAlternate')) {
        return 'Processed';
      }
      if (status === 'denied') {
        return 'Processed';
      }
      return status;
    };

    const showDisposition = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        // Status Values: submitted, qualified, qualifiedprogram, interview, approved, denied, ApprovedAlternate, returned
        if (
          originalStatus === 'submitted' ||
          originalStatus === 'qualified' ||
          originalStatus === 'qualifiedinitial' ||
          originalStatus === 'qualifiedprogram' ||
          originalStatus === 'interview'
        ) {
          rc = true;
        }
      }
      return rc;
    };

    const showSaveButton = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        // Status Values: submitted, qualified, qualifiedprogram, interview, approved, denied, ApprovedAlternate, returned
        if (
          originalStatus === 'submitted' ||
          originalStatus === 'qualified' ||
          originalStatus === 'qualifiedinitial' ||
          originalStatus === 'qualifiedprogram' ||
          originalStatus === 'interview'
        ) {
          rc = true;
        }
      } else if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        // Status Values: submitted, qualified, qualifiedprogram, interview, approved, denied, ApprovedAlternate, returned
        if (status === 'created' || status === 'returned') {
          rc = true;
        }
      }
      return rc;
    };

    const canSubmit = () => {
      let rc = true;
      let requiredFields = [];

      if (!serviceType || serviceType.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Select the service type for which you wish to apply.' }];
        rc = false;
      }

      if (form.currentStudent.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Indicate whether you are a current student at Boys Town.' }];
        rc = false;
      }

      if (form.currentStudent === 'no' && (form.addressEntered.length === 0 || form.cityStateZip.length === 0)) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'If you are not a current Boys Town student, please enter your address, city, state, and zip code.' },
        ];
        rc = false;
      }

      if (form.currentStudent === 'yes' && form.motivationSystem.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Select your current motivation system.' }];
        rc = false;
      }

      if (form.careerInterests.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Enter your career interests.' }];
        rc = false;
      }

      if (form.noWorkHistory === false && (form.workHistory[0].employer.length === 0 || form.workHistory[0].startDate === null)) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Indicate you have no work history or enter your work history employer and dates of employment.' },
        ];
        rc = false;
      }

      let totalPlansSelected = 0;
      if (form.plansCommunityCollege) totalPlansSelected += 1;
      if (form.plans4YearCollege) totalPlansSelected += 1;
      if (form.plansVocationalTraining) totalPlansSelected += 1;
      if (form.plansOther) totalPlansSelected += 1;
      if (form.plansMilitary) totalPlansSelected += 1;
      if (form.plansWorkforce) totalPlansSelected += 1;
      if (form.plansJobCorps) totalPlansSelected += 1;

      if (totalPlansSelected === 0 || totalPlansSelected > 2) {
        requiredFields = [...requiredFields, { requirement: 'Select at least one post-graduation plan.' }];
        rc = false;
      }

      if (form.plansOther === true && form.plansOtherExplain.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Explain your "other" post-graduation plan.' }];
        rc = false;
      }

      if (form.boystownEmployment.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Indicate whether you are interested in employment at Boys Town.' }];
        rc = false;
      }

      let totalBoysTownOptionsSelected = 0;
      if (form.boystownAftercare) totalBoysTownOptionsSelected += 1;
      if (form.boystownIT) totalBoysTownOptionsSelected += 1;
      if (form.boystownFacilities) totalBoysTownOptionsSelected += 1;
      if (form.boystownLegal) totalBoysTownOptionsSelected += 1;
      if (form.boystownFinance) totalBoysTownOptionsSelected += 1;
      if (form.boystownMarketing) totalBoysTownOptionsSelected += 1;
      if (form.boystownHospital) totalBoysTownOptionsSelected += 1;
      if (form.boystownSecurity) totalBoysTownOptionsSelected += 1;
      if (form.boystownHR) totalBoysTownOptionsSelected += 1;
      if (form.boystownYouthCare) totalBoysTownOptionsSelected += 1;
      if (form.boystownInfoSec) totalBoysTownOptionsSelected += 1;

      if (form.boystownEmployment === 'yes' && totalBoysTownOptionsSelected === 0) {
        requiredFields = [...requiredFields, { requirement: 'Select the areas of employment at Boys Town in which you are interested.' }];
        rc = false;
      }

      if (form.acSummerLivingArrangements.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Enter your summer living arrangements.' }];
        rc = false;
      }

      if (form.acFallLivingArrangements.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Enter your fall living arrangements.' }];
        rc = false;
      }

      if (form.otherIncome.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Indicate whether you have other sources of income.' }];
        rc = false;
      }

      if (form.otherIncome === 'yes' && form.explainOtherIncome.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Explain your other sources of income.' }];
        rc = false;
      }

      if (form.guardiansFinancialHelp.length === 0) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Explain any financial help to which your parents and/or legal guardians have committed.' },
        ];
        rc = false;
      }

      if (form.acHealthInsurance.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Indicate whether you are covered under a health insurance plan other than Medicaid.' }];
        rc = false;
      }

      if (form.essay.length === 0 && essayFile === null) {
        requiredFields = [...requiredFields, { requirement: 'Enter or attach your essay.' }];
        rc = false;
      }

      if (serviceType === 'housing' && form.housingServicesSignature.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Enter your signature in the space provided.' }];
        rc = false;
      }

      if (
        serviceType === 'housing' &&
        (!form.housingServicesDate ||
          form.housingServicesDate.isValid === false ||
          displayDateAsUTC(new Date(form.housingServicesDate)) > displayDateAsUTC(new Date()))
      ) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Enter a valid date of your signature in the space provided. The date cannot be later than today.' },
        ];
        rc = false;
      }

      if (serviceType === 'tradelife' && form.tradeLifeSignature.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Enter your signature in the space provided.' }];
        rc = false;
      }

      if (serviceType === 'tradelife' && (!form.tradeLifeDate || form.tradeLifeDate.isValid === false || new Date(form.tradeLifeDate) > new Date())) {
        requiredFields = [
          ...requiredFields,
          { requirement: 'Enter a valid date of your signature in the space provided. The date cannot be later than today.' },
        ];
        rc = false;
      }

      if (serviceType === 'housing' && (!joinBoth || joinBoth.length === 0)) {
        requiredFields = [
          ...requiredFields,
          {
            requirement:
              'Indicate whether you are interested in joining the Successful Futures - Trade Life program if you are not accepted into the Successful Futures - Housing prorgram.',
          },
        ];
        rc = false;
      }

      setRequiredFieldsIntro(
        'You have not completed all of the required information on the Successful Futures Services Application. Please make these adjustments before submitting your application.'
      );
      setRequiredFields(requiredFields);
      return rc;
    };

    const canDisposition = () => {
      let rc = true;
      let requiredFields = [];

      if (status === 'submitted') {
        requiredFields = [...requiredFields, { requirement: 'Select the status of the Successful Futures application.' }];
        rc = false;
      }

      if (status === 'interview' && (!interviewAt || (interviewAt && interviewAt.isValid === false))) {
        requiredFields = [...requiredFields, { requirement: 'A status of "Interview" requires that a date for the interview be indicated.' }];
        rc = false;
      }

      // Only check for future date if interview date was modified
      if (status === 'interview' && originalInterviewAt !== interviewAt) {
        // Verify interview date is not in the past.
        if (dateOnlyFormat(interviewAt) < getTodaysDate()) {
          requiredFields = [...requiredFields, { requirement: 'The interview date cannot be earlier than today.' }];
          rc = false;
        }
      }

      if (dispositionLetterSent && dispositionLetterSent.isValid === false) {
        requiredFields = [...requiredFields, { requirement: 'Enter a valid date for the date the Notification Letter was sent.' }];
        rc = false;
      }

      if (
        (status === 'denied' || status === 'approved' || status === 'ApprovedAlternate') &&
        (!dispositionLetterSent || (dispositionLetterSent && dispositionLetterSent.isValid === false))
      ) {
        requiredFields = [
          ...requiredFields,
          {
            requirement:
              'You must enter a valid date the Notification Letter was sent when setting the application status to "Approved" or "Denied" or "Denied Housing/Approved Trade Life."',
          },
        ];
        rc = false;
      }

      if (status !== 'denied' && status !== 'approved' && status !== 'ApprovedAlternate' && dispositionLetterSent) {
        requiredFields = [
          ...requiredFields,
          {
            requirement:
              'You must set a status of "Approved" or "Denied" or "Denied Housing/Approved Trade Life" when you enter the date the Notification Letter was sent.',
          },
        ];
        rc = false;
      }

      setRequiredFieldsIntro('Please make these adjustments before continuing.');
      setRequiredFields(requiredFields);
      return rc;
    };

    const shouldDisplayDispositionBox = () => {
      let rc = false;
      if (dispositionReason && dispositionReason.length > 0) rc = true;
      if (interviewAt && interviewAt.length > 0) rc = true;
      if (dispositionLetterSent && dispositionLetterSent.length > 0) rc = true;
      return rc;
    };

    return (
      <React.Fragment>
        {store.appCreated ? (
          <Grid container direction="row" className={formClasses.sectionSpacer}>
            <Grid item xs={false} sm={false} md={1} lg={2} align="left" />
            <Grid item xs={12} sm={12} md={10} lg={8} align="left">
              <section align="left" className={formClasses.sectionTitle}>
                <ol>
                  <li>
                    After you create your Successful Futures Services application, your responses will be automatically saved every five minutes. You
                    can return to the application to edit and save as many times as you wish.
                  </li>
                  <li>Once you are satisfied with your application, use the "Submit" button to send the application in for consideration.</li>
                  <li>Once submitted, your application cannot be modified.</li>
                </ol>
              </section>
              <section align="center">
                <Button color="primary" variant="contained" onClick={(event) => handleUnderstand()} style={{ margin: '10px' }}>
                  I understand
                </Button>
              </section>
            </Grid>
            <Grid item xs={false} sm={false} md={1} lg={2} align="left" />
          </Grid>
        ) : (
          store.senior && (
            <Grid container key={store.senior.userId} align="left">
              {(auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && (
                <Grid item xs={12} align="center" className={formClasses.textRowLabelSpacer}>
                  {status === 'returned' ? (
                    <aside className={formClasses.error}>
                      NOTE: This Successful Futures Services application was returned for additional information. Please make the necessary changes
                      and re-submit.
                    </aside>
                  ) : (
                    <aside style={{ fontWeight: 600, color: status === 'returned' ? 'red' : '#000' }}>
                      Current Status: {titleize(displayStatus(status))}
                    </aside>
                  )}
                </Grid>
              )}

              {shouldDisplayDispositionBox() && (
                <Grid item xs={12} className={formClasses.dispositionNote}>
                  {dispositionReason && dispositionReason.length > 0 && (
                    <React.Fragment>
                      <summary style={{ fontWeight: 600 }}>Notes Regarding Status</summary>
                      <summary>{dispositionReason}</summary>
                    </React.Fragment>
                  )}
                  {interviewAt > /Date(0)/ && (
                    <summary className={formClasses.sectionSpacer}>
                      <label style={{ fontWeight: 600 }}>Interview Date</label> {dateUtcToS(interviewAt) || null}
                    </summary>
                  )}
                  {dispositionLetterSent > /Date(0)/ && (
                    <summary className={formClasses.sectionSpacer}>
                      <label style={{ fontWeight: 600 }}>Notification Letter Sent</label> {dateUtcToS(dispositionLetterSent) || null}
                    </summary>
                  )}
                </Grid>
              )}

              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} align="left">
                  <section align="left" className={formClasses.sectionTitle}>
                    Type of Service
                  </section>
                </Grid>
              </Grid>
              <ServiceType
                className={formClasses.topMargin10}
                serviceType={serviceType}
                setServiceType={setServiceType}
                joinBoth={joinBoth}
                setJoinBoth={setJoinBoth}
                disabled={isEditDisabled()}
                roles={auth.roles}
              />
              <Grid container direction="row">
                <Grid item xs={12} align="left" className={formClasses.firstSectionSpacer}>
                  <section align="left" className={formClasses.firstSectionTitle}>
                    Applicant Information
                  </section>
                </Grid>
              </Grid>
              {store.senior && (
                <GeneralInformation senior={store.senior} form={form} setForm={setForm} disabled={isEditDisabled()} roles={auth.roles} />
              )}
              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} align="left">
                  <section align="left" className={formClasses.sectionTitle}>
                    Career Interests
                  </section>
                </Grid>
              </Grid>
              <CareerInterests form={form} setForm={setForm} disabled={isEditDisabled()} handleWorkHistoryRow={handleWorkHistoryRow} />
              <Grid container direction="row">
                <Grid item xs={12}>
                  <section align="left" className={formClasses.sectionTitle}>
                    Letters of Recommendation
                  </section>
                  <Box sx={{ width: '95%' }}>
                    <section>
                      REMINDER: Two recommendation letters are required as part of your application. To request recommendations, or view the status of
                      pending recommendation requests, please visit the Letters of Recommendation page.
                    </section>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} align="left">
                  <section align="left" className={formClasses.sectionTitle}>
                    Essay
                  </section>
                </Grid>
              </Grid>
              <Essay
                className={formClasses.topMargin10}
                form={form}
                setForm={setForm}
                essayFile={essayFile}
                setEssayFile={setEssayFile}
                appId={store.aftercareAppId}
                disabled={isEditDisabled()}
                status={status}
              />

              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} align="left">
                  <section align="left" className={formClasses.sectionTitle}>
                    Informed Consent
                  </section>
                </Grid>
              </Grid>
              <InformedConsent
                className={formClasses.topMargin10}
                form={form}
                setForm={setForm}
                serviceType={serviceType}
                joinBoth={joinBoth}
                disabled={isEditDisabled()}
              />

              {showDisposition() && (
                <React.Fragment>
                  <Grid
                    container
                    direction="row"
                    className={formClasses.unprintedContent + ' ' + formClasses.reviewSection}
                    style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
                  >
                    <div className={formClasses.reviewStatus}>
                      <Grid item xs={12} align="center">
                        <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
                      </Grid>
                      <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer}>
                        <label style={{ fontWeight: 600, color: '#000000' }}>Application Status</label>
                      </Grid>
                      <Grid item xs={12} sm={12} md={9} lg={10} className={formClasses.textArea5RowSpacer}>
                        <FormControl fullWidth>
                          <RadioGroup
                            row
                            aria-label="approve application"
                            name="approve-application"
                            horizontal="true"
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                          >
                            <FormControlLabel
                              value="returned"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Returned
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="qualifiedinitial"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Qualified - Initial
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="qualifiedprogram"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Qualified - Program
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="interview"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Interview Scheduled
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="approved"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Approved
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="ApprovedAlternate"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Denied Housing/Approved Trade Life
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="denied"
                              className={formClasses.marginRight40}
                              control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                              label={
                                <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                                  Denied
                                </span>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </div>
                    {(status === 'returned' || status === 'denied' || status === 'approved' || status === 'ApprovedAlternate') && (
                      <div className={formClasses.reason}>
                        <Grid item xs={12} className={formClasses.textRowSpacer}>
                          <FormControl fullWidth>
                            <TextField
                              id="disposition-reason"
                              size="small"
                              fullWidth
                              label={`Enter the reason for status '${titleize(status)}'`}
                              variant="outlined"
                              value={dispositionReason}
                              onChange={(event) => setDispositionReason(event.target.value)}
                              disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                              InputProps={{ maxLength: 1000, classes: { input: formClasses.textField } }}
                              className={formClasses.root}
                            />
                          </FormControl>
                        </Grid>
                      </div>
                    )}
                    <div className={formClasses.reviewDates}>
                      <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer}>
                        <label style={{ fontWeight: 600, color: '#000000' }}>Interview Date</label>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} className={formClasses.datePickerRowSpacer}>
                        {auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST') ? (
                          <React.Fragment>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                              <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                id="interview-date"
                                hiddenLabel
                                // disablePast
                                value={interviewAt ? dateUtcToS(interviewAt) : null}
                                onChange={(date) => handleInterviewAtChange(date ? date : null)}
                                format="MM/dd/yyyy"
                                className={formClasses.datepicker}
                                disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                                // minDateMessage="Date cannot be earlier than today."
                                invalidDateMessage={'Interview Date is invalid.'}
                              />
                            </MuiPickersUtilsProvider>
                            {interviewAtSet && displayDateAsUTC(new Date(interviewAt)) < displayDateAsUTC(new Date()) && (
                              <aside className={formClasses.warning}>The Interview Date is in the past.</aside>
                            )}
                          </React.Fragment>
                        ) : (
                          <summary style={{ marginTop: 3 }} className={formClasses.subtleBold}>
                            Interview Date: {dateUtcToS(interviewAt || null)}
                          </summary>
                        )}
                      </Grid>
                      <Grid item xs={false} sm={1} md={5} lg={1} />
                      <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer}>
                        <label style={{ fontWeight: 600, color: '#000000' }}>Notification Letter Sent</label>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5} lg={3} className={formClasses.datePickerRowSpacer}>
                        {auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST') ? (
                          <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              id="disposition-letter-sent"
                              hiddenLabel
                              disableFuture
                              value={dispositionLetterSent ? dateUtcToS(dispositionLetterSent) : null}
                              onChange={(date) => setDispositionLetterSent(date ? date : null)}
                              format="MM/dd/yyyy"
                              disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                              className={formClasses.datepicker}
                              maxDateMessage="Date cannot be later than today."
                            />
                          </MuiPickersUtilsProvider>
                        ) : (
                          <summary style={{ marginTop: 3 }} className={formClasses.subtleBold}>
                            Notification Letter Sent: {dateUtcToS(dispositionLetterSent) || null}
                          </summary>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </React.Fragment>
              )}

              <Grid container direction="row" className={formClasses.unprintedContent}>
                <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.buttonSpacer}>
                  {(status === 'created' || status === 'returned') && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && (
                    <Tooltip title="Click to submit your application for consideration." placement="top">
                      <Button
                        className={formClasses.buttonWidth}
                        color="secondary"
                        variant="contained"
                        onClick={(event) => handleSubmitApplication()}
                        disabled={!store.aftercareApp}
                        style={{ margin: '0 0 0 10px' }}
                      >
                        Submit
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={2} lg={1}></Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} align="right" className={formClasses.buttonSpacer}></Grid>
              </Grid>
            </Grid>
          )
        )}
        <div align="right">
          {showSaveButton() && (
            <Button
              className={formClasses.floatingButton}
              color="primary"
              variant="contained"
              onClick={(event) => handleSaveApplication()}
              disabled={!store.aftercareApp}
              style={{ margin: '0 10px 0 10px' }}
            >
              Save
            </Button>
          )}
        </div>
        <RequiredInfoDialog
          title="Successful Futures Application Errors"
          introduction={requiredFieldsIntro}
          content={requiredFields}
          showDialog={showRequiredFieldsDialog}
          handleClose={handleRequiredInfoClose}
        />
      </React.Fragment>
    );
  })
);

AftercareApp.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default AftercareApp;
