import { makeAutoObservable } from 'mobx';

const initialState = {
  loading: true,
  searching: false,
  seniordata: [],
  allseniors: [],
  totalSeniors: 0,
  senior: {},
  senior_planning_1: {},
  senior_planning_2: {},
  showBasicInfo: false,
  basicInfoId: 0,
  showAddEmail: false,
  addEmailId: 0,
  showAllRecords: false,
  showNeedEmail: false,
  showCompletedScholarshipApps: false,
  seniorPlan1: {},
  seniorPlan2: {},
  checklist: {},
  aftercareList: [],
  aftercareAppId: 0,
  aftercareApp: {},
  helpAppList: [],
  scholarshipList: [],
  recommendationList: [],
  helpAppId: 0,
  helpApp: {},
  contactId: 0,
  contact: {},
  scholarshipAppId: 0,
  scholarshipApp: {},
  newrecommendation: false,
  recommendationId: 0,
  recommendation: {},
  recommendationEmail: '',
  aftercareServiceTypeFilter: '',
  aftercareStatusFilter: '',
  aftercareSpecialistList: [],
  appCreated: false,
  page: 0,
  perPage: 15,
  advancedFilter: 'all',
  advancedFilterStatus: '',
  advancedFilterLoggedIn: '',
  advancedFilterSite: '',
  advancedFilterProgram: '',
  advancedFilterChecklist: '',
  advancedFilterPlanningForm1: '',
  advancedFilterPlanningForm2: '',
  advancedFilterScholarship: '',
  advancedFilterAftercare: '',
  alumni: false,
  reports: [],
  staffsites: [],
  checkInId: null,
  checkIn: {},
  checklistObj: {},
  planform1Obj: {},
  planform2Obj: {},
  scholarships: [],
  allAwards: [],
  appAwards: [],
  planningForm1Data: null,
  planningForm2Data: null,
};

class SeniorStore {
  constructor() {
    // makeAutoObservable(this);
    Object.assign(this, initialState);
    makeAutoObservable(this, {}, { autoBind: true });
    this.loadState();
  }

  get seniorPages() {
    // return Math.ceil(this.totalSeniors / this.perPage);
    return this.totalSeniors || 0;
  }

  get getAddEmailSenior() {
    let senior = null;
    if (this.seniordata && this.seniordata.length > 0) {
      senior = this.seniordata.filter((s) => s.id === this.addEmailId);
    }
    if (senior && senior.length === 1) {
      return senior[0];
    }
    return null;
  }

  get hasPendingScholarshipApp() {
    if (!this.scholarshipList || this.scholarshipList.length === 0) {
      return false;
    }
    return (
      this.scholarshipList.findIndex(
        (s) =>
          s.status === 'created' ||
          s.status === 'submitted' ||
          s.status === 'qualified' ||
          s.status === 'qualifiedinitial' ||
          s.status === 'qualifiedprogram' ||
          s.status === 'returned',
      ) > -1
    );
  }

  get hasPendingHelpApp() {
    if (!this.helpAppList || this.helpAppList.length === 0) {
      return false;
    }
    // return this.helpAppList.findIndex((s) => s.status === 'created' || s.status === 'submitted') > -1;
    return this.helpAppList;
  }

  resetState() {
    Object.assign(this, initialState);
    this.saveState();
  }

  removeFromSession() {
    this.resetState();
    sessionStorage.removeItem('SeniorStore');
  }

  setBasicInfoId = (val) => {
    this.basicInfoId = val;
    this.saveState();
  };

  setLoading = (val) => {
    this.loading = val;
    this.saveState();
  };

  setSearching = (val) => {
    this.searching = val;
    this.saveState();
  };

  setShowAllRecords = (checked) => {
    this.showAllRecords = checked;
    this.showNeedEmail = false;
    this.saveState();
  };

  setShowNeedEmail = (checked) => {
    this.showNeedEmail = checked;
    this.showAllRecords = false;
    this.saveState();
  };

  setShowCompletedScholarshipApps = (checked) => {
    this.showCompletedScholarshipApps = checked;
    this.saveState();
  };

  setShowBasicInfo = (val) => {
    this.showBasicInfo = val;
    this.saveState();
  };

  setSenior = (obj) => {
    this.senior = obj;
    this.saveState();
  };

  setSeniorForm2 = (obj) => {
    this.seniorForm2 = obj;
    this.saveState();
  };

  setSeniorPlan1 = (obj) => {
    this.seniorPlan1 = obj;
    this.saveState();
  };

  setSeniorPlan2 = (obj) => {
    this.seniorPlan2 = obj;
    this.saveState();
  };

  setChecklist = (obj) => {
    this.checklist = obj;
    this.saveState();
  };

  setChecklistObj = (obj) => {
    this.checklistObj = obj;
    this.saveState();
  };

  setPlanform1Obj = (obj) => {
    this.planform1Obj = obj;
    this.saveState();
  };

  setPlanform2Obj = (obj) => {
    this.planform2Obj = obj;
    this.saveState();
  };

  setAppCreated = (val) => {
    this.appCreated = val;
    this.saveState();
  };

  setAftercareList = (obj) => {
    this.aftercareList = obj;
    this.saveState();
  };

  setAftercareApp = (obj) => {
    this.aftercareApp = obj;
    this.saveState();
  };

  setAftercareAppId = (id) => {
    this.aftercareAppId = id;
    this.saveState();
  };

  setAftercareServiceTypeFilter = (val) => {
    this.aftercareServiceTypeFilter = val;
    this.saveState();
  };

  setAftercareStatusFilter = (val) => {
    this.aftercareStatusFilter = val;
    this.saveState();
  };

  setAlumni = (val) => {
    this.alumni = val;
    this.saveState();
  };

  setHelpAppList = (obj) => {
    this.helpAppList = obj;
    this.saveState();
  };

  setScholarshipList = (obj) => {
    this.scholarshipList = obj;
    this.saveState();
  };

  setRecommendationList = (obj) => {
    this.recommendationList = obj;
    this.saveState();
  };

  setHelpApp = (obj) => {
    this.helpApp = obj;
    this.saveState();
  };

  setContact = (obj) => {
    this.contact = obj;
    this.saveState();
  };

  setScholarshipApp = (obj) => {
    this.scholarshipApp = obj;
    this.saveState();
  };

  setRecommendation = (obj) => {
    this.recommendation = obj;
    this.saveState();
  };

  setNewRecommendation = (val) => {
    this.newrecommendation = val;
    this.saveState();
  };

  setHelpAppId = (id) => {
    this.helpAppId = id;
    this.saveState();
  };

  setContactId = (id) => {
    this.contactId = id;
    this.saveState();
  };

  setScholarshipAppId = (id) => {
    this.scholarshipAppId = id;
    this.saveState();
  };

  setRecommendationId = (id) => {
    this.recommendationId = id;
    this.saveState();
  };

  setCheckInId = (id) => {
    this.checkInId = id;
    this.saveState();
  };

  setCheckIn = (obj) => {
    this.checkIn = obj;
    this.saveState();
  };

  setRecommendationEmail = (email) => {
    this.recommendationEmail = email;
    this.saveState();
  };

  setAllSeniors = (data) => {
    this.allseniors = data;
    this.saveState();
  };

  setReports = (data) => {
    this.reports = data;
    this.saveState();
  };

  setSeniorData = (data) => {
    if (data) {
      this.seniordata = data;
      this.totalSeniors = data.length;
      this.saveState();
    }
  };

  setTotalSeniors = (val) => {
    this.totalSeniors = val;
    this.saveState();
  };

  setShowAddEmail = (val) => {
    this.showAddEmail = val;
    this.saveState();
  };

  setAddEmailId = (val) => {
    this.addEmailId = val;
    this.saveState();
  };

  setAftercareSpecialistList = (obj) => {
    this.aftercareSpecialistList = obj;
    this.saveState();
  };

  setScholarships = (list) => {
    this.scholarships = list;
    this.saveState();
  };

  setAllAwards = (list) => {
    this.allAwards = list;
    this.saveState();
  };

  setAppAwards = (list) => {
    this.appAwards = list;
    this.saveState();
  };

  setPlanningForm1Data = (data) => {
    this.planningForm1Data = data;
    this.saveState();
  };

  setPlanningForm2Data = (data) => {
    this.planningForm2Data = data;
    this.saveState();
  };

  setPage = (page) => {
    this.page = page;
    this.saveState();
  };

  async handlePageChange(ev, val) {
    this.searching = true;
    this.page = val;
    this.searching = false;
    this.saveState();
  }

  handleChangeRowsPerPage = (event) => {
    this.perPage = parseInt(event.target.value, 10);
    this.page = 0;
    this.saveState();
  };

  setAdvancedFilter = (val) => {
    this.advancedFilter = val;
    this.saveState();
  };

  setAdvancedFilterStatus = (val) => {
    this.advancedFilterStatus = val;
    this.saveState();
  };

  setAdvancedFilterLoggedIn = (val) => {
    this.advancedFilterLoggedIn = val;
    this.saveState();
  };

  setAdvancedFilterSite = (val) => {
    this.advancedFilterSite = val;
    this.saveState();
  };

  setAdvancedFilterProgram = (val) => {
    this.advancedFilterProgram = val;
    this.saveState();
  };

  setAdvancedFilterChecklist = (val) => {
    this.advancedFilterChecklist = val;
    this.saveState();
  };

  setAdvancedFilterPlanningForm1 = (val) => {
    this.advancedFilterPlanningForm1 = val;
    this.saveState();
  };

  setAdvancedFilterPlanningForm2 = (val) => {
    this.advancedFilterPlanningForm2 = val;
    this.saveState();
  };

  setAdvancedFilterScholarship = (val) => {
    this.advancedFilterScholarship = val;
    this.saveState();
  };

  setAdvancedFilterAftercare = (val) => {
    this.advancedFilterAftercare = val;
    this.saveState();
  };

  setStaffSites = (sites) => {
    if (sites) {
      this.staffsites = sites;
      this.saveState();
    }
  };

  saveState() {
    const state = {
      senior: this.senior,
      basicInfoId: this.basicInfoId,
    };
    sessionStorage.setItem('SeniorStore', JSON.stringify(state));
  }

  loadState() {
    const state = JSON.parse(sessionStorage.getItem('SeniorStore'));
    if (state) {
      Object.assign(this, state);
    }
  }
}

export default SeniorStore;
